import { createSelector, Selector } from '@reduxjs/toolkit';
import { selectCartItems, selectStoreId } from '../../redux/cart/general/cartSelectors';
import selectAddress from '../../redux/cart/general/selectAddress';
import { RootState } from '../../redux/reducers';
import { UseScheduleDeliveryProps } from './useScheduleDelivery';


const selectScheduleDeliveryProps: Selector<RootState, UseScheduleDeliveryProps> = createSelector(
  [
    selectStoreId,
    selectAddress,
    selectCartItems,
    state => state.app.cartType,
    state => state.serverCart.data?.etag,
  ],
  (
    storeId,
    address,
    cartItems,
    cartType,
    cartETag,
  ) => ({
    storeId,
    address,
    cartItems,
    cartType,
    cartETag,
  }),
);

export default selectScheduleDeliveryProps;
