import { ActionReducerMapBuilder, createAction } from '@reduxjs/toolkit';
import { Dispatch } from 'redux';
import { selectAppConfig } from 'src/utils/appConfig/selectAppConfig';
import { createAction as createCacheAction } from '../apiCacheMiddleware';
import { RSAAActionErrorPayload } from '../apiTypes';
import { RootState } from '../reducers';
import { RetailChain, RetailChainState } from './types';

const getRetailChainRequest = createAction(
  'retailChain/getRetailChainRequest',
);

const getRetailChainSuccess = createAction<RetailChain>(
  'retailChain/getRetailChainSuccess',
);

const getRetailChainError = createAction<RSAAActionErrorPayload>(
  'retailChain/getRetailChainError',
);

const requestGetRetailChain = () => (dispatch: Dispatch, getState: () => RootState) => {
  const state = getState();
  const { fetchConfig } = selectAppConfig(state);
  const { xChain, xVersion, apiRoot, language } = fetchConfig;

  const action = createCacheAction(
    {
      cacheKey: `chain-${xChain}-${language}`,
      successActionType: getRetailChainSuccess.type,
    },
    {
      method: 'GET',
      endpoint: `${apiRoot}/retail_chains/${xChain}`,
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'x-version': xVersion,
        'Accept-Language': language,
      },
      types: [
        getRetailChainRequest.type,
        getRetailChainSuccess.type,
        getRetailChainError.type,
      ],
    },
  );

  return dispatch(action);
};

export default requestGetRetailChain;

export function addGetRetailChain(
  builder: ActionReducerMapBuilder<RetailChainState>,
) {
  builder
    .addCase(getRetailChainRequest, state => {
      state.data = null;
      state.status = 'loading';
      state.error = null;
    })
    .addCase(getRetailChainSuccess, (state, action) => {
      state.data = action.payload;
      state.status = 'succeeded';
      state.error = null;
    })
    .addCase(getRetailChainError, (state, action) => {
      state.data = null;
      state.status = 'failed';
      state.error = action.payload;
    });
}
