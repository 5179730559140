import { Department } from 'src/modules/AddressManagement/AddressManagement.types';
import { FetchConfig } from 'src/redux/apiTypes';

export async function fetchDepartments(
  localityId: string,
  fetchConfig: FetchConfig,
  typeOfWarehouse?: string,
): Promise<Response | Department[]> {
  const { xChain, xVersion, apiRoot, language } = fetchConfig;
  const typeNP = typeOfWarehouse ? `/?type=${typeOfWarehouse}` : '';

  const url = `${apiRoot}/delivery_third_party/nova_poshta/settlements/${localityId}/warehouses${typeNP}`;

  const options: RequestInit = {
    method: 'GET',
    headers: {
      'Accept-Language': language,
      'x-chain': xChain,
      'x-version': xVersion,
    },
  };

    try {
      return await fetch(url, options);
    } catch (_error) {
      return {} as Response;
    }
}

export async function getDepartments(
  localityId: string,
  fetchConfig: FetchConfig,
  typeOfWarehouse?: string,
): Promise<Department[]> {
  const encodedSearchString = encodeURIComponent(localityId);
  const response = await fetchDepartments(encodedSearchString, fetchConfig, typeOfWarehouse);

  if ((response as Response).ok) {
    const departments = await (response as Response).json();

    return departments.map((d) => ({
      ...d,
      /**
       * From the server, we get the weight in kilograms,
       * but milligrams are used everywhere in the app
       */
      max_weight_allowed: d.max_weight_allowed * 1000,
    }));
  }

  return [];
}
