import Cookies from 'js-cookie';
import { ParsedUrlQuery } from 'querystring';
import { ADMITAD_COOKIE_NAME, SALESDOUBLER_COOKIE_NAME } from '../../redux/constants';

export const updateAffiliateProgramsCookie = (
  query: ParsedUrlQuery, host: string, WITH_COOKIE_POLICY?: boolean,
  ) => {

  if (
    !query.admitad_uid &&
    !query.aff_sub &&
    !query.utm_source &&
    !query.gclid
  ) {
    return;
  }

  const admitadCookie = Cookies.get(ADMITAD_COOKIE_NAME);
  const salesDoublerCookie = Cookies.get(SALESDOUBLER_COOKIE_NAME);
  const topHost = host.split('/')[0].split('.').slice(-2).join('.');
  const cookiesAreAllowed = !WITH_COOKIE_POLICY || Cookies.get('cookiesAllowed') === 'true';

  if (query.admitad_uid) {
    cookiesAreAllowed && setAdmitadCookie(query.admitad_uid as string, topHost);
    salesDoublerCookie && removeSalesDoublerCookie(topHost);
  }
  else if (query.aff_sub) {
    cookiesAreAllowed && setSalesDoublerCookie(query.aff_sub as string, topHost);
    admitadCookie && removeAdmitadCookie(topHost);
  }
  else {
    salesDoublerCookie && removeSalesDoublerCookie(topHost);
    admitadCookie && removeAdmitadCookie(topHost);
  }
};

export const removeAffiliateProgramsCookie = (host: string) => {
  const salesDoublerCookie = Cookies.get(SALESDOUBLER_COOKIE_NAME);

  if (salesDoublerCookie) {
    const topHost = host.split(':')[0].split('.').slice(-2).join('.');
    removeSalesDoublerCookie(topHost);
  }
};

const setAdmitadCookie = (value: string, host: string) => {
  Cookies.set(
    ADMITAD_COOKIE_NAME,
    value,
    {
      expires: 30,
      domain: `.${host}`,
    },
  );
};

const removeAdmitadCookie = (host: string) => {
  Cookies.remove(
    ADMITAD_COOKIE_NAME,
    {
      domain: `.${host}`,
    },
  );
};

const setSalesDoublerCookie = (value: string, host: string) => {
  Cookies.set(
    SALESDOUBLER_COOKIE_NAME,
    value,
    {
      expires: 30,
      domain: `.${host}`,
    },
  );
};

const removeSalesDoublerCookie = (host: string) => {
  Cookies.remove(
    SALESDOUBLER_COOKIE_NAME,
    {
      domain: `.${host}`,
    },
  );
};
