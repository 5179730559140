import Cookies from 'js-cookie';
import { ADMITAD_COOKIE_NAME, SALESDOUBLER_COOKIE_NAME } from '../../redux/constants';
import { Language } from '../appConfig/getInitialAppConfig';

export interface CheckoutMarketingData {
  admitad_uid?: string;
  sd_uid?: string;
  ga: {
    dl: string;
    cid?: string;
    session_id_chain?: string;
    session_id_crossdomain?: string;
  }
}

const getMarketingDataForCheckout = (language: Language, host: string): CheckoutMarketingData => {
  const marketing: CheckoutMarketingData = {
    ga: {
      dl: `https://${host}/${language}/thanks`,
      session_id_chain: Cookies.get('session_id_chain'),
      session_id_crossdomain: Cookies.get('session_id_crossdomain'),
      cid: Cookies.get('_ga')?.slice(6),
    },
    sd_uid: Cookies.get(SALESDOUBLER_COOKIE_NAME),
    admitad_uid: Cookies.get(ADMITAD_COOKIE_NAME),
  };

  //stringify and parse to remove undefined properties if the cookie is not set
  return JSON.parse(JSON.stringify(marketing));
};

export default getMarketingDataForCheckout;
