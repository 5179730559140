import { DepartmentOption, LocalityOption } from '../../../modules/AddressManagement/AddressManagement.types';
import { Address, AddressType } from '../../addressManagement/types';
import { Store } from '../../apiTypes';

import { ServerCartDelivery, ServerCartNovaPoshtaSettlement, ServerCartNovaPoshtaWarehouse } from './types';

function convertToAddress(
  delivery: ServerCartDelivery, presets: ServerCartDelivery[], store: Store,
): Address {
  const { plan, pickup, nova_poshta, nova_poshta_fresh, nova_poshta_address } = delivery.address;

  const addressType: AddressType = presets?.some(p => p.hash === delivery.hash)
    ? 'savedAddress'
    : 'newAddress';

  const lat =
    plan?.coords?.lat ||
    pickup?.store?.address?.coords?.lat ||
    store?.address?.coords?.lat;

  const lng =
    plan?.coords?.lng ||
    pickup?.store?.address?.coords?.lng ||
    store?.address?.coords?.lng;

  let locality: LocalityOption;
  let department: DepartmentOption;

  if (nova_poshta || nova_poshta_fresh) {
    let settlement: ServerCartNovaPoshtaSettlement;
    let warehouse: ServerCartNovaPoshtaWarehouse;

    if (nova_poshta_fresh) {
      settlement = nova_poshta_fresh.settlement;
      warehouse = nova_poshta_fresh.warehouse;
    }

    if (nova_poshta) {
      settlement = nova_poshta.settlement;
      warehouse = nova_poshta.warehouse;
    }

    const {
      id: settlementId,
      name: settlementName,
      warehouse: isWarehouse,
      address_delivery_not_allowed,
    } = settlement;

    const {
      id: warehouseId, name: warehouseName, max_weight_allowed, number, coords,
    } = warehouse;

    locality = {
      id: settlementId,
      label: settlementName,
      value: settlementId,
      warehouse: isWarehouse,
      address_delivery_not_allowed,
    };

    department = {
      id: warehouseId,
      label: warehouseName,
      lowerCaseLabel: warehouseName.toLocaleLowerCase(),
      value: warehouseId,
      max_weight_allowed: max_weight_allowed * 1000,
      number,
      coords,
      type: warehouse.type,
    };
  }

  if (nova_poshta_address) {
    const settlement: ServerCartNovaPoshtaSettlement = nova_poshta_address.address.settlement;

    const {
      id: settlementId,
      name: settlementName,
      warehouse: isWarehouse,
      address_delivery_not_allowed,
    } = settlement;

    locality = {
      id: settlementId,
      label: settlementName,
      value: settlementId,
      warehouse: isWarehouse,
      address_delivery_not_allowed,
    };
  }

  const address: Address = {
    id: delivery.hash,
    delivery: delivery.type,
    city:
      plan?.city ||
      pickup?.store?.address?.city ||
      store?.address?.city,
    street:
      plan?.street ||
      pickup?.store?.address?.street ||
      nova_poshta_address?.address.street ||
      store?.address?.street,
    building:
      plan?.building ||
      pickup?.store?.address?.building ||
      nova_poshta_address?.address.building ||
      store?.address?.building,
    coords: `${lat}, ${lng}`,
    addressType,
    addressData: store,
    pickupZone: pickup?.pickup_zone,
    locality,
    department,
  };

  return address;
}

export default convertToAddress;
