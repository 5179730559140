import { FetchConfig, ShoppingListItemWithListId } from "src/redux/apiTypes";

interface Params {
  fetchConfig: FetchConfig;
  items: ShoppingListItemWithListId[];
}

const requestPostFavoritesItems = async (params: Params): Promise<void> => {
  const {
    fetchConfig,
    items,
  } = params;
  const { xChain, xVersion, apiRoot, language } = fetchConfig;

  const response = await fetch(`${apiRoot}/user/lists/items`, {
    method: 'POST',
    credentials: 'include',
    headers: {
      'Accept-Language': language,
      'Content-Type': 'application/json',
      'x-chain': xChain,
      'x-version': xVersion,
    },
    body: JSON.stringify({ items }),
  });

  if (!response.ok) {
    throw new Error('Post favorite lists items error');
  }

  return;
};

export default requestPostFavoritesItems;
