import { Selector, createSelector } from '@reduxjs/toolkit';
import { RootState } from '../../reducers';
import getCartDeliveryForNewAddress from '../serverCart/getCartDeliveryForNewAddress';
import { ServerCartDelivery } from '../serverCart/types';

const selectSelectedDelivery: Selector<RootState, ServerCartDelivery | null> =
  createSelector(
    [
      (s) => s.app.cartType,
      (s) => s.addressManagement.address,
      (s) => s.serverCart.data?.delivery || null,
    ],
    (cartType, clientCartDelivery, serverCartDelivery) => {
      if (!cartType) {
        return null;
      }

      if (cartType === 'client') {
        if(!Object.keys(clientCartDelivery).length) {
          return null;
        }

        return getCartDeliveryForNewAddress(clientCartDelivery);
      }

      return serverCartDelivery;
    },
  );

export default selectSelectedDelivery;
