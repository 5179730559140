import { END_PREPARE_CATEGORY, RESET_CATEGORY_DATA, SET_CATEGORY_IS_UNAVAILABLE_FOR_NP, SET_IMPRESSION_LIST_NAME, START_PREPARE_CATEGORY } from "./catalogueTypes";


export const resetCategoryData = () => dispatch => {
  return dispatch({
    type: RESET_CATEGORY_DATA,
  });
};

export const setImpressionListName = name => dispatch => {
  return dispatch({
    type: SET_IMPRESSION_LIST_NAME,
    payload: name,
  });
};

export const resetImpressionListName = () => dispatch => {
  return dispatch({
    type: SET_IMPRESSION_LIST_NAME,
    payload: null,
  });
};

export const startPrepareCategory = () => {
  return {
    type: START_PREPARE_CATEGORY,
  };
};


export const endPrepareCategory = () => {
  return {
    type: END_PREPARE_CATEGORY,
  };
};

export const setIsUnavailableForNP = (isUnavailable: boolean) => {
  return {
    type: SET_CATEGORY_IS_UNAVAILABLE_FOR_NP,
    payload: isUnavailable,
  };
};
