import createReducer from '../createReducer';
import { setAddress, setAddressIsChecked } from './addressManagementActions';
import { AddressManagementState } from './types';

export const addressManagementInitialState: AddressManagementState = {
  address: {},
  addressIsChecked: false,
};

const actionHandlers = {
  [setAddress.type]: (state, action) => (
    {
      ...state,
      address: action.payload,
    }
  ),
  [setAddressIsChecked.type]: (state) => ({
    ...state,
    addressIsChecked: true,
  }),
};

const reducer = createReducer(
  addressManagementInitialState,
  actionHandlers,
);

export default reducer;
