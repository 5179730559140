import { getCartPrice } from 'src/redux/cart/clientCart/operations/getCartPrice';
import { ServerCartDelivery } from 'src/redux/cart/serverCart/types';
import { ReduxThunkAction } from 'src/redux/reducers';
import { setAddress } from '../../../redux/addressManagement/addressManagementActions';
import { Store } from '../../../redux/apiTypes';
import selectSelectedDelivery from '../../../redux/cart/general/selectSelectedDelivery';
import convertToAddress from '../../../redux/cart/serverCart/convertToAddress';
import requestPostCartDelivery from '../../../redux/cart/serverCart/requestPostCartDelivery';
import setStore from '../../../redux/storeManagement/setStore';
import { setAddressInLocalStorage } from '../../../utils/delivery/addressLocalStorage';
import setDeliveryTypeCookie from '../../../utils/delivery/setDeliveryTypeCookie';
import setStoreIdCookie from '../../../utils/delivery/setStoreIdCookie';

const setNewDelivery = (newDelivery: ServerCartDelivery, newStore: Store): ReduxThunkAction<boolean> =>
  async (dispatch, getState) => {
    const state = getState();
    const prevStoreId = state.storeManagement.store.id;
    const newStoreId = newStore.id;
    const prevDeliveryType = selectSelectedDelivery(state)?.type;
    const newDeliveryType = newDelivery.type;
    const cartType = state.app.cartType;
    const onPageReload = state.modals.sharedData?.onPageReload;
    const onSetNewDelivery = state.modals.sharedData?.onSetNewDelivery;

    if (typeof onSetNewDelivery === 'function') {
      onSetNewDelivery();
    }

    const storeIdIsChanged = prevStoreId !== newStoreId;
    const deliveryTypeIsChanged = prevDeliveryType !== newDeliveryType;
    const pageShouldBeReloaded = storeIdIsChanged || deliveryTypeIsChanged;

    if (storeIdIsChanged) {
      dispatch(setStore(newStore));
    }

    if (cartType === 'client') {
      const newAddress = convertToAddress(newDelivery, [], newStore);
      setAddressInLocalStorage(newAddress);
      dispatch(setAddress(newAddress));
      dispatch(getCartPrice(true));
    }

    if (cartType === 'server') {
      await dispatch(requestPostCartDelivery({ delivery: newDelivery }));
    }

    setStoreIdCookie(newStoreId);
    setDeliveryTypeCookie(newDeliveryType);

    if (pageShouldBeReloaded) {
      if(typeof onPageReload === 'function') {
        onPageReload();
      }

      window.location.reload();
    }

    return pageShouldBeReloaded;
  };

export default setNewDelivery;
