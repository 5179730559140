import { ActionReducerMapBuilder, createAction, Dispatch } from "@reduxjs/toolkit";
import { CategoryFilterLike, RSAAActionErrorPayload } from "src/redux/apiTypes";
import { RootState } from "src/redux/reducers";
import { selectAppConfig } from 'src/utils/appConfig/selectAppConfig';
import { createAction as createCacheAction } from '../../apiCacheMiddleware';
import selectAddress from '../../cart/general/selectAddress';
import { CategoriesReducersState } from "../categoriesReducers";

const getFiltersLikeCategoriesListRequest = createAction('categories/getFiltersLikeCategoriesList');
const getFiltersLikeCategoriesListSuccess = createAction<CategoryFilterLike[]>('categories/getFiltersLikeCategoriesListSuccess');
const getFiltersLikeCategoriesListError = createAction<RSAAActionErrorPayload>('categories/getFiltersLikeCategoriesListError');

export const requestGetFiltersLikeCategoriesList = () =>
  (dispatch: Dispatch, getState: () => RootState) => {
    const state = getState();
    const storeId = state.storeManagement.store.id;
    const isLoggedIn = state.user.isLoggedIn;
    const { delivery } = selectAddress(state);
    const { fetchConfig } = selectAppConfig(state);
    const { xChain, apiRoot, language, xVersion } = fetchConfig;

    const action = createCacheAction<RootState, CategoryFilterLike[], never>(
      {
        cacheKey: `filters-like-categories-${storeId}-${language}-${delivery}-${isLoggedIn}`,
        successActionType: getFiltersLikeCategoriesListSuccess.type,
      },
      {
        method: 'GET',
        endpoint: `${apiRoot}/stores/${storeId}/categories/filters-like/`,
        headers: {
          'Accept-Language': language,
          'Content-Type': 'application/json',
          'x-chain': xChain,
          'x-version': xVersion,
          'X-Delivery-Type': delivery,
        },
        credentials: 'include',
        types: [
          getFiltersLikeCategoriesListRequest.type,
          getFiltersLikeCategoriesListSuccess.type,
          getFiltersLikeCategoriesListError.type,
        ],
      },
    );

    return dispatch(action);
  };

export function addGetFiltersLikeCategoriesList(
  builder: ActionReducerMapBuilder<CategoriesReducersState>,
) {
  builder
    .addCase(getFiltersLikeCategoriesListSuccess, (state, action) => {
      state.filtersLikeCategories = action.payload;
    })
    .addCase(getFiltersLikeCategoriesListError, (state) => {
      state.filtersLikeCategories = [];
    });
}
