import Cookies from 'js-cookie';
import { useRouter } from 'next/router';
import { useEffect, useRef, useState } from 'react';
import { useAppConfig } from 'src/utils/appConfig/useAppConfig';

export interface Query {
  utm_source: string;
  utm_medium: string;
  utm_campaign: string;
}

export interface AppLinks {
  android: string | null;
  ios: string | null;
}

export function useAppLinks(): AppLinks {
  const { query } = useRouter<Query>();
  const { chain, marketing, country } = useAppConfig();
  const { ANDROID_APP_ID, IOS_APP_ID } = marketing;

  const [appLinks, setAppLinks] = useState<AppLinks>({ ios: null, android: null });
  const [cid, setCid] = useState<string | null>(null);

  const timeoutId = useRef<number>();
  const intervalId = useRef<number>();

  function updateCid() {
    const cidFromCookies = Cookies.get('_ga')?.slice(6);

    if (cidFromCookies) {
      setCid(cidFromCookies);
      return;
    }

    intervalId.current = window.setInterval(() => {
      const cidFromCookies = Cookies.get('_ga')?.slice(6);

      if (cidFromCookies) {
        window.clearInterval(intervalId.current);
        setCid(cidFromCookies);
      }

    }, 300);

    timeoutId.current = window.setTimeout(function() {
      window.clearInterval(intervalId.current);
    }, 3000);
  }

  useEffect(() => {
    return () => {
      clearInterval(intervalId.current);
      clearTimeout(timeoutId.current);
    };
  }, []);

  useEffect(() => {
    if (ANDROID_APP_ID || IOS_APP_ID) {

      /**
       * Have to check for 'gci' (Google Client ID), because for users without this cookie
       * creation of new 'gci' will happen after loading GTM and GA scripts (245 KB).
       */
      updateCid();

      /**
       * Creating session cookies to store utm data while session lives
       */
      query.utm_source && Cookies.set('utm_source', query.utm_source);
      query.utm_medium && Cookies.set('utm_medium', query.utm_medium);
      query.utm_campaign && Cookies.set('utm_campaign', query.utm_campaign);

      /**
       * Docs about deep links forming rules can be found here:
       * https://zakaz.atlassian.net/browse/SHWEB-7081
       */
      const utmSource = Cookies.get('utm_source') || (cid ? 'google' : 'noutm');
      const utmMedium = Cookies.get('utm_medium') || (cid ? 'cpc' : 'noutm');
      const utmCampaign = Cookies.get('utm_campaign') || (cid ? 'gclid' : 'noutm');
      const partner = chain;

      const utmCampaignString = `${utmCampaign}_web_id_${cid || 'nocid'}_web_site_${partner}`;
      const utmParams = `referrer=${utmSource}&utm_source=${utmSource}&utm_medium=web_${utmMedium}&utm_campaign=${utmCampaignString}`;

      if (country === 'md') {
        const android = ANDROID_APP_ID ? `https://play.google.com/store/apps/details?id=${ANDROID_APP_ID}&${utmParams}` : null;
        const ios = IOS_APP_ID ? `https://apps.apple.com/${country}/app/id${IOS_APP_ID}?${utmParams}` : null;

        setAppLinks({ ios, android });
      } else {
        const universalDynamicLink = `https://app.zakaz.${country}/?link=https://zakaz.${country}/app&apn=${ANDROID_APP_ID}&isi=${IOS_APP_ID}&ibi=${country}.zakaz?id=${ANDROID_APP_ID}&${utmParams}`;

        setAppLinks({ ios: universalDynamicLink, android: universalDynamicLink });
      }
    }
  }, [
    query.utm_source,
    query.utm_medium,
    query.utm_campaign,
    cid,
    chain,
    ANDROID_APP_ID,
    IOS_APP_ID,
    country,
  ]);

  return appLinks;
}
