import Cookies from 'js-cookie';
import { Address } from 'src/redux/addressManagement/types';
import { IS_SERVER } from "../../redux/constants";

const key = 'address';

export function setAddressInLocalStorage(address?: Address) {
  if (!IS_SERVER) {
    if (address) {
      localStorage?.setItem(key, JSON.stringify(address));
    } else {
      localStorage?.removeItem(key);
    }
  }
}

function hasAddressCoords(address: Address) {
  if (address.delivery === "nova_poshta" || address.delivery === "nova_poshta_fresh") {
    return !!(address.department && address.department.coords);
  }
  return !!address.coords;
}

export function getAddressFromLocalStorage() {
  const addressJson = !IS_SERVER
    ? localStorage?.getItem(key)
    : undefined;

  const address: Address | Record<string, never> = addressJson
  ? JSON.parse(addressJson)
  : {};

  if (!hasAddressCoords(address)) {
    localStorage?.removeItem(key);
    Cookies.remove('storeId');
    Cookies.remove('deliveryType');
    return {};
  }

  return address;
}
