export const TRACK_PRODUCT_CLICK_EVENT = 'TRACK_PRODUCT_CLICK_EVENT';
export const TRACK_PRODUCT_IMPRESSION_EVENT = 'TRACK_PRODUCT_IMPRESSION_EVENT';
export const TRACK_ORDER_CLICK_EVENT = 'TRACK_ORDER_CLICK_EVENT';
export const TRACK_PROMO_COUPON_APPLIED = 'TRACK_PROMO_COUPON_APPLIED';
export const TRACK_SEARCH = 'TRACK_SEARCH';
export const TRACK_BACK_TO_SHOPPING = 'TRACK_BACK_TO_SHOPPING';
export const TRACK_PRODUCT_DETAILS_EVENT = 'TRACK_PRODUCT_DETAILS_EVENT';
export const TRACK_REMARKETING_PRODUCTS = 'TRACK_REMARKETING_PRODUCTS';
export const TRACK_REMARKETING_CATEGORY_PRODUCTS = 'TRACK_REMARKETING_CATEGORY_PRODUCTS';
export const TRACK_REMARKETING_ORDER_HISTORY_PRODUCTS = 'TRACK_REMARKETING_ORDER_HISTORY_PRODUCTS';
