import dynamic from 'next/dynamic';
import { RenderAfterInteract } from 'src/modules/interact-watcher';

const Messengers = dynamic(() => import('./Messengers'), { ssr: false });

export interface Props {
  zendeskKey: string;
}

export default function MessengersWrapper(props: Props) {
  const { zendeskKey } = props;

  return (
    <RenderAfterInteract>
      <Messengers zendeskKey={zendeskKey} />
    </RenderAfterInteract>
  );
}
