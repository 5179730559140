import { Dispatch } from 'redux';
import { NpStreet } from "src/modules/AddressManagement/AddressManagement.types";
import { modalsManager } from 'src/utils/system/modalsManager';
import { openModal } from "../../../modals";
import { RootState } from "../../../reducers";
import { ClientCartRequest, ClientCartRequestCartItem, ClientCartRequestDeliveryData } from "../clientCartTypes";
import requestPostCartPrice from "../requestPostClientCart";
import { restoreCartPrice } from "../restoreCartPrice";

export const getCartPrice = (ignoreEmptyCart?: boolean) => (
  dispatch: Dispatch,
  getState: () => RootState,
) => {
  const state = getState();
  const { cart } = state.cart;

  if (cart.length === 0 && !ignoreEmptyCart) {
    return dispatch(restoreCartPrice());
  }

  const availableCart = cart.filter(
    item => item.isAvailable && item.amount > 0,
  );

  if (availableCart.length === 0 && !ignoreEmptyCart) {
    return dispatch(restoreCartPrice());
  }

  const { address } = state.addressManagement;

  const cartData: ClientCartRequestCartItem[] = availableCart.map(({ ean, amount }) => ({
    ean,
    amount,
  }));

  const deliveryData: ClientCartRequestDeliveryData = {
    type: address.delivery,
  };

  if (address.delivery === 'plan') {
    deliveryData.address = {
      coords: address.coords
        ? `${address.coords.replace(/\s/, '')}` //     /\s/ or ' '
        : null,
    };
  }
  else if (address.delivery === 'nova_poshta') {
    deliveryData.nova_poshta = {
      settlement_id: address.locality.value,
      warehouse_id: address.department.value,
      type: address.department.type,
    };
  }
  else if (address.delivery === 'nova_poshta_fresh') {
    deliveryData.nova_poshta_fresh = {
      settlement_id: address.locality.value,
      warehouse_id: address.department.value,
      type: address.department.type,
    };
  }
  else if (address.delivery === 'nova_poshta_address') {
    const { id } = address.street as NpStreet;

    deliveryData.nova_poshta_address = {
      address: {
        settlement: {
          id: address.locality.value,
        },
        street: {
          id: id,
        },
      },
    };
  }

  if (!deliveryData.type) {
    if (!ignoreEmptyCart) {
      dispatch(openModal('addressModal', undefined, { onPageReload:() => modalsManager.addModal('cartModal') }));
    }

    // in some places the error property in the response object is expected
    return Promise.resolve({ error: true });
  }

  const requestBody: ClientCartRequest = {
    delivery: deliveryData,
    cart: cartData,
  };

  return dispatch(requestPostCartPrice(requestBody));
};
