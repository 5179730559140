import { NotificationFavoriteData } from 'src/redux/notification/types';
import { getAmountedPrice } from 'src/utils/cart/products';
import { v4 as getGUID } from 'uuid';
import { weightMeasureEnum } from '../../fixtures';
import { ChooseAnotherDeliveryMethodPlace } from '../../hooks/useChooseAnotherDeliveryType/useChooseAnotherDeliveryType';
import { CartContacts, DeliveryType, OrderItem, Product, ProductUnit } from '../../redux/apiTypes';
import { CartItem } from '../../redux/cart/general/cartTypes';
import { ProductWithAmount } from '../../redux/modals/types';
import { ScheduleDeliveryList } from '../../swr/useScheduleDelivery/scheduleDeliveryTypes';
import { Currency } from '../appConfig/getInitialAppConfig';
import { formatPrice, getPriceStringByUnit } from '../product/formatPrice';
import { gramsToKilos } from '../product/weightConverter';

interface eventParams {
  amount?: number;
  place?: string;
  currency: string;
  eanAmountMap?: {
    [ean: string]: {
      amount: number
    }
  }
}

if (typeof window !== 'undefined') window.dataLayer = window.dataLayer || [];

// ============= CONSTANTS =============

export const BY_WEIGHT = 'By weight';
export const BY_PIECE = 'By piece';
export const ORDER_BUTTON_CLICKED = 'ORDER_BUTTON_CLICKED';
export const CHECKOUT_PAGE_VISITED = 'CHECKOUT_PAGE_VISITED';
export const REGISTRATION_COMPLETED = 'REGISTRATION_COMPLETED';
export const CONTACTS_ENTERED = 'CONTACTS_ENTERED';
export const PRODUCT_REPLACEMENT_FILLED = 'PRODUCT_REPLACEMENT_FILLED';
export const ADDRESS_CONFIRMED = 'ADDRESS_CONFIRMED';
export const PAYMENT_METHOD_CONFIRMED = 'PAYMENT_METHOD_CONFIRMED';
export const TIME_WINDOW_PICKED = 'TIME_WINDOW_PICKED';
export const ORDER_SUBMITTED = 'ORDER_SUBMITTED';
export const COUPON_APPLIED = 'Coupon applied';
export const NO_COUPON = 'No coupon';

export const impressionListEnum = {
  MAIN_PAGE_PROMOTIONS: 'Main page promotions',
  MAIN_PAGE_B2B_PRODUCTS: 'Main page business products',
  MAIN_PAGE_POPULAR_PRODUCTS: 'Main page popular products',
  MAIN_PAGE_SPECIAL_PRODUCTS: 'Main page special products',
  FIRST_LEVEL_CATEGORY: 'First level category',
  SECOND_LEVEL_CATEGORY: 'Second level category',
  THIRD_LEVEL_CATEGORY: 'Third level category',
  PROMOTIONS: 'Promotions',
  B2B_PRODUCTS: 'B2B products',
  SEARCH: 'Search',
  FAVORITES: 'Favorites',
  DIRECT_LINK: 'Direct link',
  CART: 'Basket',
  MAIN_PAGE_WIDGETS: 'Widget main page',
};

export const productClickEventEnum = {
  MAIN_PAGE_PROMOTIONS: 'main page promotion',
  MAIN_PAGE_B2B_PRODUCTS: 'main page business products',
  MAIN_PAGE_WIDGETS: 'widget main page',
  FIRST_LEVEL_CATEGORY: 'first level category',
  SECOND_LEVEL_CATEGORY: 'second level category',
  THIRD_LEVEL_CATEGORY: 'third level category',
  SIMILAR: 'product cart - similar   ',
  BUY_WITH: 'product cart - buy with ',
  SEEN_BY_OTHERS: 'product cart - seen by others ',
  CART: 'cart',
  FAVORITES: 'list',
  SEARCH: 'search',
  SEARCH_BOX: 'search - auto_suggest',
  PROMOTIONS: 'promotions',
  B2B_PRODUCTS: 'b2b_products',
  ORDER_HISTORY: 'order_history',
};

export const ecommerceCheckoutStepsEnum = {
  [ORDER_BUTTON_CLICKED]: 1,
  [CHECKOUT_PAGE_VISITED]: 2,
  [REGISTRATION_COMPLETED]: 3,
  [CONTACTS_ENTERED]: 4,
  [PRODUCT_REPLACEMENT_FILLED]: 5,
  [ADDRESS_CONFIRMED]: 6,
  [PAYMENT_METHOD_CONFIRMED]: 7,
  [TIME_WINDOW_PICKED]: 8,
  [ORDER_SUBMITTED]: 9,
};

export const addToCartButtonsEnum = {
  CATEGORY: 'Category',
  PRODUCT_CARD: 'Product card',
  PRODUCT_CARD_SIMILAR: 'Product card - similar',
  PRODUCT_CARD_BUY_WITH: 'Product card - buy with',
  PRODUCT_CARD_SEEN_BY_OTHERS: 'Product card - seen by others',
  CART: 'Cart',
  CHECKOUT: 'Checkout',
  SHOPPING_LIST: 'Shopping list',
  FAVORITES: 'Favorites',
  FAVORITES_FULL: 'Favorites full',
  PROMOTIONS: 'Promotions',
  SEARCH: 'Search',
  SEARCH_BOX: 'search - auto_suggest',
  MAIN_PAGE_PROMOTIONS: 'Main page promotions',
  MAIN_PAGE_B2B_PRODUCTS: 'Main page business products',
  MAIN_PAGE_WIDGETS: 'Widget main page',
  SPECIAL_PRODUCTS_SLIDER: 'specially_for_you',
  LAST_VIEW_PRODUCTS_SLIDER: 'recently_viewed_block',
  POPULAR_PRODUCTS_SLIDER: 'block_popular',
  ORDERS_HISTORY: 'Orders history',
  ORDERS_HISTORY_FULL: 'Orders history full',
  EDIT_SEARCH: 'edit_search',
  EDIT_PRODUCT_CARD: 'edit_product_cart',
  EDIT_MENU: 'edit_menu',
  EXTRA_FEEDBACK_PAGE: 'extra_feedback_page',
  RECOMMENDED_CART: 'recommended_cart',
};

export const ecommerceFavoritesButtonTypeEnum = {
  PRODUCT_IN_LISTING: 'Товар в листинге',
  PRODUCT_IN_CART: 'Товар в корзине',
  WHOLE_CART: 'Вся корзина',
  ORDER_HISTORY: 'История покупок',
  PRODUCT_CARD: 'Карточка товара',
};

export const remarketingPageTypesEnum = {
  MAIN: 'main',
  CATALOG: 'catalog',
  PRODUCT: 'product',
  SEARCH: 'search',
  CART: 'cart',
  PROMOTION: 'promotion',
  B2B: 'b2b products',
  ACCOUNT: 'account',
  LISTS: 'lists',
  SUCCESS: 'success',
};

// ============= DATA CONVERTERS =============

interface ImpressedProduct {
  name: string
  id: string
  price: string
  brand: string
  category: string
  variant: string
  list: string
  position: number
}

interface ProductInCart {
  name: string,
  id: string,
  price: string,
  brand: string | null,
  category: string,
  variant: string,
  quantity: number,
}

export const makeProductImpression = (
  product: Product, list: string, position: number,
): ImpressedProduct => {
  const {
    slug: name,
    ean: id,
    price,
    producer,
    category_id: category,
    unit,
  } = product;

  const brand = producer?.trademark || 'null';

  return {
    name,
    id,
    price: getPriceStringByUnit(price, unit),
    brand: brand,
    category,
    variant: unit === weightMeasureEnum.kg ? BY_WEIGHT : BY_PIECE,
    list,
    position,
  };
};

export const getProductsFromCart = (cart: CartItem[]): ProductInCart[] => {
  return cart.map(item => {
    const product = item.product as Product;

    return {
      name: product.slug,
      id: product.ean,
      price: getPriceStringByUnit(product.price, product.unit),
      brand: product?.producer?.trademark || null,
      category: product.category_id,
      variant: product.unit === weightMeasureEnum.kg ? BY_WEIGHT : BY_PIECE,
      quantity: item.amount,
    };
  });
};

// ============= API INTERACTORS =============

export const sendImpression = (impression: ImpressedProduct) => {
  window.dataLayer.push({
    ecommerce: {
      currencyCode: 'UAH',
      impressions: [impression],
    },
    event: 'gtmUaEvent',
    gtmUaEventCategory: 'Enhanced Ecommerce',
    gtmUaEventAction: 'Product Impressions',
    gtmUaEventNonInteraction: 'True',
  });
};

interface AdditionalFields {
  gtmUaEventLabel: string
}

export const sendCheckoutStep = (
  stepName: string,
  products: ProductInCart[],
  stepOption?: string,
  additionalFields?: AdditionalFields,
) => {
  const step = ecommerceCheckoutStepsEnum[stepName];
  const actionField = stepOption ? { step, option: stepOption } : { step };
  window.dataLayer.push({
    ecommerce: {
      currencyCode: 'UAH',
      checkout: {
        actionField: actionField,
        products,
      },
    },
    event: 'gtmUaEvent',
    gtmUaEventCategory: 'Enhanced Ecommerce',
    gtmUaEventAction: stepName,
    ...additionalFields,
    gtmUaEventNonInteraction:
      step === ecommerceCheckoutStepsEnum.CHECKOUT_PAGE_VISITED
        ? 'True'
        : 'False',
  });
};

export const sendProductClick = (product: Product, list: string, position: number) => {
  const {
    currency,
    slug,
    ean,
    unit,
    price,
    producer,
    category_id,
  } = product;

  window.dataLayer.push({
    ecommerce: {
      currencyCode: currency,
      click: {
        actionField: {
          list,
        },
        products: [
          {
            name: slug,
            id: ean,
            price: getPriceStringByUnit(price, unit),
            brand: producer ? producer.trademark : 'null',
            category: category_id,
            variant: unit === weightMeasureEnum.kg ? BY_WEIGHT : BY_PIECE,
            position,
          },
        ],
      },
    },
    event: 'gtmUaEvent',
    gtmUaEventCategory: 'Enhanced Ecommerce',
    gtmUaEventAction: 'Product Clicks',
    gtmUaEventNonInteraction: 'False',
    gtmUaEventLabel: list,
  });
};

export interface ProductDetails {
  currency: string
  slug: string
  ean: string
  unit: ProductUnit
  price: number
  trademark: string | null
  category_id: string
}

export const sendProductDetails = (product: ProductDetails, impressionListName: string) => {
  const {
    currency,
    slug,
    ean,
    unit,
    price,
    trademark,
    category_id,
  } = product;

  window.dataLayer.push(
    {
      ecommerce: {
        currencyCode: currency,
        detail: {
          actionField: {
            list: impressionListName,
          },
          products: [
            {
              name: slug,
              id: ean,
              price: getPriceStringByUnit(price, unit),
              brand: trademark || 'null',
              category: category_id,
              variant: unit === weightMeasureEnum.kg ? BY_WEIGHT : BY_PIECE,
            },
          ],
        },
      },
      event: 'gtmUaEvent',
      gtmUaEventCategory: 'Enhanced Ecommerce',
      gtmUaEventAction: 'Product Details',
      gtmUaEventNonInteraction: 'True',
    },
  );
};

export const sendAddingSingleProductToCart = (product: Product, params: eventParams) => {
  const { amount, place, currency } = params;
  const { slug, ean, price, producer, category_id, unit, hits } = product;

  const stockBalanceEventMap = {
    in_stock: 'in_stock',
    low_stock: 'not_much_left',
    running_out: 'a_few_left',
  };

  window.dataLayer.push({
    ecommerce: {
      currencyCode: currency,
      add: {
        products: [
          {
            name: slug,
            id: ean,
            price: getPriceStringByUnit(price, unit),
            brand: producer ? producer.trademark : 'null',
            category: category_id,
            variant: unit === weightMeasureEnum.kg ? BY_WEIGHT : BY_PIECE,
            quantity: amount,
            now_viewing: hits || 0,
            amount_stocks: stockBalanceEventMap[product.verbose_stock],
          },
        ],
      },
    },
    event: 'gtmUaEvent',
    gtmUaEventCategory: 'Enhanced Ecommerce',
    gtmUaEventAction: 'Adding a Product to a Shopping Cart',
    gtmUaEventLabel: place || 'no event label',
    gtmUaEventNonInteraction: 'False',
  });
};

export const sendAddingProductsToCart = (products: Array<Product | OrderItem>, params: eventParams) => {
  const { eanAmountMap = {}, place, currency } = params;
  const ecommerceProducts = products.map(product => {
    const { slug, ean, price, producer, category_id, unit, amount } = product as Product & OrderItem;
    const attachedAmount = eanAmountMap[ean] && eanAmountMap[ean].amount;

    return {
      name: slug,
      id: ean,
      price: getPriceStringByUnit(price, unit),
      brand: producer ? producer.trademark : null,
      category: category_id,
      variant: unit === weightMeasureEnum.kg ? BY_WEIGHT : BY_PIECE,
      quantity: amount || attachedAmount,
    };
  });

  window.dataLayer.push({
    ecommerce: {
      currencyCode: currency,
      add: {
        products: ecommerceProducts,
      },
    },
    event: 'gtmUaEvent',
    gtmUaEventCategory: 'Enhanced Ecommerce',
    gtmUaEventAction: 'Adding Products to a Shopping Cart',
    gtmUaEventLabel: place || 'no event label',
    gtmUaEventNonInteraction: 'False',
  });
};

export const sendRemovingSingleProductFromCart = (product: Product, params: eventParams) => {
  const { amount, place, currency } = params;
  const { slug, ean, price, producer, category_id, unit } = product;

  window.dataLayer.push({
    ecommerce: {
      currencyCode: currency,
      remove: {
        products: [
          {
            name: slug,
            id: ean,
            price: getPriceStringByUnit(price, unit),
            brand: producer ? producer.trademark : 'null',
            category: category_id,
            variant: unit === weightMeasureEnum.kg ? BY_WEIGHT : BY_PIECE,
            quantity: amount,
          },
        ],
      },
    },
    event: 'gtmUaEvent',
    gtmUaEventCategory: 'Enhanced Ecommerce',
    gtmUaEventAction: 'Removing a Product from a Shopping Cart',
    gtmUaEventLabel: place || 'no event label',
    gtmUaEventNonInteraction: 'False',
  });
};

export const sendRemovingProductsFromCart = (products: ProductWithAmount[], params: eventParams) => {
  const { eanAmountMap = {}, place } = params;
  const { currency } = products[0];
  const ecommerceProducts = products.map(product => {
    const { slug, ean, price, producer, category_id, unit, amount } = product;
    const attachedAmount = eanAmountMap[ean] && eanAmountMap[ean].amount;

    return {
      name: slug,
      id: ean,
      price: getPriceStringByUnit(price, unit),
      brand: producer ? producer.trademark : null,
      category: category_id,
      variant: unit === weightMeasureEnum.kg ? BY_WEIGHT : BY_PIECE,
      quantity: amount || attachedAmount,
    };
  });

  window.dataLayer.push({
    ecommerce: {
      currencyCode: currency,
      remove: {
        products: ecommerceProducts,
      },
    },
    event: 'gtmUaEvent',
    gtmUaEventCategory: 'Enhanced Ecommerce',
    gtmUaEventAction: 'Removing Products from a Shopping Cart',
    gtmUaEventLabel: place || 'no event label',
    gtmUaEventNonInteraction: 'False',
  });
};

export const sendSignInClick = () => {
  window.dataLayer.push({
    event: 'gtmUaEvent',
    gtmUaEventCategory: 'Вход',
    gtmUaEventAction: 'Клик',
    gtmUaEventNonInteraction: 'False',
  });
};

export const sendAuthRegPopupView = () => {
  window.dataLayer.push({
    event: 'virtualPageView',
    virtualPageURL: '/registration-enter/popup-show/',
  });
};

export const sendRegPhoneSent = () => {
  window.dataLayer.push({
    event: 'virtualPageView',
    virtualPageURL: '/registration/phone-sent/',
  });
};

export const sendRegSuccess = () => {
  window.dataLayer.push({
    event: 'virtualPageView',
    virtualPageURL: '/registration/success/',
  });
};

export const sendPasswordRecoveryShow = () => {
  window.dataLayer.push({
    event: 'virtualPageView',
    virtualPageURL: '/password-recovery/popup-show/',
  });
};

export const sendNewPasswordSent = () => {
  window.dataLayer.push({
    event: 'virtualPageView',
    virtualPageURL: '/password-recovery/new-password-sent/',
  });
};

export const sendAuthSuccess = () => {
  window.dataLayer.push({
    event: 'virtualPageView',
    virtualPageURL: '/authorization/success/',
  });
};

export const sendAddToCartGoal = (buttonType) => () => {
  window.dataLayer.push({
    'event': 'gtmUaEvent',
    'gtmUaEventCategory': 'Добавления товаров в корзину',
    'gtmUaEventAction': `${buttonType}`,
    'gtmUaEventNonInteraction': 'False',
  });
};

export const sendOrderClickGoal = () => {
  window.dataLayer.push({
    'event': 'gtmUaEvent',
    'gtmUaEventCategory': 'Заказать',
    'gtmUaEventAction': 'Корзина',
    'gtmUaEventNonInteraction': 'False',
  });
};

export const sendFilterExpanded = (category, filter) => {
  window.dataLayer.push({
    'event': 'gtmUaEvent',
    'gtmUaEventCategory': 'Развертывания фильтров',
    'gtmUaEventAction': `${category} - ${filter}`,
    'gtmUaEventNonInteraction': 'False',
  });
};

export const sendFilterChoose = (filterType: string) => {
  window.dataLayer.push({
    'event': 'product_filter_applied',
    'variant': filterType,
  });
};

export const sendSearch = () => {
  window.dataLayer.push({
    'event': 'gtmUaEvent',
    'gtmUaEventCategory': 'Поиск по сайту',
    'gtmUaEventAction': 'Продукты',
    'gtmUaEventNonInteraction': 'False',
  });
};

export const sendBackToShopping = () => {
  window.dataLayer.push({
    'event': 'gtmUaEvent',
    'gtmUaEventCategory': 'Возврат в магазин после покупки',
    'gtmUaEventAction': 'Клик',
    'gtmUaEventNonInteraction': 'False',
  });
};

export const sendClickOnCustomerReview = () => {
  window.dataLayer.push({
    'event': 'gtmUaEvent',
    'gtmUaEventCategory': 'Behaviour',
    'gtmUaEventAction': 'Click',
    'gtmUaEventLabel': ' Google_Customer_Review_v2',
  });
};

export const sendClickOnOpenCustomerReviewModal = () => {
  window.dataLayer.push({
    'event': 'gtmUaEvent',
    'gtmUaEventCategory': 'Behaviour',
    'gtmUaEventAction': 'Click',
    'gtmUaEventLabel': ' Customer_Negative_Button ',
  });
};

export const sendSubmitCustomerReviewModalForm = () => {
  window.dataLayer.push({
    'event': 'gtmUaEvent',
    'gtmUaEventCategory': 'Behaviour',
    'gtmUaEventAction': 'Click',
    'gtmUaEventLabel': ' Customer_Negative_Form ',
  });
};

/**
 * @deprecated create / use function below for specific page type
 */
export const sendRemarketingProducts = (items, page_type: string) => {
  const product_ids: string[] = [];
  let total_value = 0;

  if (
    page_type === remarketingPageTypesEnum.SUCCESS ||
    page_type === remarketingPageTypesEnum.CART
  ) {
    items.forEach(item => {
      if (item.product) {
        product_ids.push(item.product.ean);
        // +(value).toFixed() is used to prevent well-known 0.1 + 0.2 = 0.3000...4 issue
        total_value += +(item.amount * item.product.price).toFixed();
      }
    });
  } else {
    items.forEach(product => {
      product_ids.push(product.ean);
      total_value += +product[product.total_price ? 'total_price' : 'price'].toFixed();
    });
  }

  const eventObj = {
    product_ids,
    page_type,
    total_value: formatPrice(total_value),
    ...page_type === remarketingPageTypesEnum.LISTS && { event: 'list_open' },
  };

  window.dataLayer.push(eventObj);
};

export const sendRemarketingCategoryProducts = (
  products: Product[],
  page_type: string,
  category_id: string,
) => {
  const product_ids: string[] = [];
  let total_value = 0;

  products.forEach(product => {
    product_ids.push(product.ean);
    total_value += +product['total_price' in product ? 'total_price' : 'price'].toFixed();
  });

  const eventObj = {
    product_ids,
    page_type,
    total_value: formatPrice(total_value),
    category_id,
  };

  window.dataLayer.push(eventObj);
};

export function sendProductRemarketingProducts(ean: string, price: number) {
  const event = {
    product_ids: [ean],
    page_type: remarketingPageTypesEnum.PRODUCT,
    total_value: formatPrice(Number(price?.toFixed())),
  };

  window.dataLayer.push(event);
}

export const sendRemarketingOrderHistory = (orderItems: OrderItem[], totalPrice: number): void => {
  const eventObj = {
    product_ids: orderItems.map(p => p.ean),
    page_type: remarketingPageTypesEnum.ACCOUNT,
    total_value: formatPrice(totalPrice),
  };
  window.dataLayer.push(eventObj);
};

export interface SendCartParams {
  cartItems: CartItem[],
  itemsPrice: number,
  currency: Currency,
}

export const sendCart = (params: SendCartParams) => {
  const { cartItems, currency, itemsPrice } = params;
  const { product_ids, items } = getProductIdsAndItemsForCart(cartItems);

  window.dataLayer.push({
    items,
    product_ids,
    currency,
    value: itemsPrice / 100,
    event: 'cart_open',
  });
};

export const sendOrderId = (order_id: string): void => {
  window.dataLayer.push({
    Order_id: order_id,
    event: 'order_id_set',
  });
};

// Save previous page location cause `document.referrer` does not work on SPA,
// but we need this for custom behaviour in Google Tag Manager.

export const sendReferrerUrl = url => {
  window.dataLayer.push({ "referrer": url });
};

export const sendDimensionSet = (chain, store_id, client_id) => {
  window.dataLayer.push({
    STORE_ID: store_id,
    CHAIN: chain,
    CLIENT_ID: client_id,
    event: "dimensions_set",
  });
};

export const sendUserId = (user_id) => {
  if (!user_id) return;

  window.dataLayer.push({
    userId: user_id,
    event: 'user_id_set',
  });
};

interface SendUserDataParams {
  email: string;
  phone: string;
  firstName: string;
}

export const sendUserData = (params: SendUserDataParams) => {
  const { email, firstName, phone } = params;

  window.dataLayer.push({
    'event': 'user_data_esp',
    'email': email,
    'phone': phone,
    'first_name': firstName,
  });
};

export const sendNotFoundPage = () => {
  window.dataLayer.push({
    'event': 'page_404',
  });
};

export const sendCategoryPage = (title: string) => {
  window.dataLayer.push({
    'event': 'category_page',
    'category_key': title,
  });
};

// Banners

export const sendBannerImpression = params => {
  window.dataLayer.push({
    ecommerce: {
      promoView: {
        promotions: [
          {
            id: params.id || '',
            name: params.name || '',
            position: 'slot' + params.slotNumber,
          },
        ],
      },
    },
    event: 'gtmUaEvent',
    gtmUaEventCategory: 'Enhanced Ecommerce',
    gtmUaEventAction: 'Promotion Impression',
    gtmUaEventNonInteraction: 'True',
  });
};

interface SendBannerClickParams {
  id: string;
  name: string;
  destinationUrl: string;
  slotNumber: number;
  target: string;
  CHAIN_GTM_ID: string | null;
}

export const sendBannerClick = (params: SendBannerClickParams) => {
  window.dataLayer.push({
    ecommerce: {
      promoClick: {
        promotions: [
          {
            id: params.id || '',
            name: params.name || '',
            position: 'slot' + params.slotNumber,
          },
        ],
      },
    },
    event: 'gtmUaEvent',
    gtmUaEventCategory: 'Enhanced Ecommerce',
    gtmUaEventAction: 'Promotion Click',
    gtmUaEventNonInteraction: 'True',
    eventCallback: function (gtm_id) {
      if (gtm_id === params.CHAIN_GTM_ID && params.destinationUrl)
        window.open(params.destinationUrl, params.target);
    },
  });
};

// product comments

export const sendSaveProductComment = (place: string) => {
  window.dataLayer.push({
    event: 'product_comment_left',
    place,
  });
};

// slider 'view all' click

export const sendViewAllUpperClick = () => {
  window.dataLayer.push({
    'event': 'gtmUaEvent',
    'gtmUaEventCategory': 'Behaviour',
    'gtmUaEventAction': 'Click',
    'gtmUaEventLabel': ' ShowAll_Upper',
  });
};

export const sendViewAllTileClick = () => {
  window.dataLayer.push({
    'event': 'gtmUaEvent',
    'gtmUaEventCategory': 'Behaviour',
    'gtmUaEventAction': 'Click',
    'gtmUaEventLabel': ' ShowAll_Carousel',
  });
};

// accident remove click
export const sendAccidentRemoveClick = () => {
  window.dataLayer.push({
    'event': 'gtmUaEvent',
    'gtmUaEventCategory': 'Behaviour',
    'gtmUaEventAction': 'Click',
    'gtmUaEventLabel': 'accident_remove',
  });
};

export const sendPerformanceMetrics = ({ name, id, value, label }) => {
  const isWebVitals = label === 'web-vital';
  // Assumes the global `dataLayer` array exists, see:
  // https://developers.google.com/tag-manager/devguide
  window.dataLayer.push({
    event: 'web-metrics',
    event_category: isWebVitals ? 'Web Vitals' : 'Next.js custom metric',
    event_action: name,
    // Google Analytics metrics must be integers, so the value is rounded.
    // For CLS the value is first multiplied by 1000 for greater precision
    // (note: increase the multiplier for greater precision if needed).
    event_value: Math.round(name === 'CLS' ? value * 1000 : value),
    // The `id` value will be unique to the current page load. When sending
    // multiple values from the same page (e.g. for CLS), Google Analytics can
    // compute a total by grouping on this ID (note: requires `eventLabel` to
    // be a dimension in your report).
    event_label: id,
  });
};

export const sendReplaceProduct = (label: string) => {
  window.dataLayer.push({
    event: 'gtmUaEvent',
    gtmUaEventCategory: 'Behaviour',
    gtmUaEventAction: 'Click',
    gtmUaEventLabel: label,
  });
};

export const sendReplaceProductWithPrice = (label: string, value: number) => {
  window.dataLayer.push({
    event: 'gtmUaEvent',
    gtmUaEventCategory: 'Behaviour',
    gtmUaEventAction: 'Click',
    gtmUaEventLabel: label,
    gtmUaEventValue: value,
  });
};

export const sendStartSearch = (searchString: string, count: number, place: string) => {
  window.dataLayer.push({
    event: 'gtmUaEvent',
    gtmUaEventCategory: 'search - start',
    gtmUaEventAction: searchString,
    gtmUaEventLabel: count,
    gtmUaEventNonInteraction: 'True',
    place: place,
  });
};

export const sendOpenSearch = () => {
  window.dataLayer.push({
    event: "search_open",
  });
};

export const sendOpenSearchPage = (searchString: string, count: number, place: string) => {
  window.dataLayer.push({
    event: 'gtmUaEvent',
    gtmUaEventCategory: 'search - full',
    gtmUaEventAction: searchString,
    gtmUaEventLabel: count,
    gtmUaEventNonInteraction: 'True',
    place: place,
  });
};

export const sendCategoryClick = (category: string, place: string) => {
  window.dataLayer.push({
    event: 'gtmUaEvent',
    gtmUaEventCategory: 'search_catalogue',
    gtmUaEventAction: 'click',
    gtmUaEventLabel: category,
    gtmUaEventNonInteraction: 'True',
    place: place,
  });
};

export const sendOrderCancelSuccess = (orderId: string, totalPrice: number) => {
  window.dataLayer.push({
    event: 'gtmUaEvent',
    gtmUaEventCategory: 'order_cancel',
    gtmUaEventAction: orderId,
    gtmUaEventLabel: formatPrice(totalPrice),
    gtmUaEventNonInteraction: 'False',
  });
};

export const sendOrderShow = (orderId: string, price: number) => {
  window.dataLayer.push({
    'event': 'gtmUaEvent',
    'gtmUaEventCategory': 'order_info',
    'gtmUaEventAction': orderId,
    'gtmUaEventLabel': formatPrice(price),
    'gtmUaEventNonInteraction': 'False',
  });
};

export const sendOrderUpdated = (orderId: string, price: number) => {
  window.dataLayer.push({
    'event': 'gtmUaEvent',
    'gtmUaEventCategory': 'order_update',
    'gtmUaEventAction': orderId,
    'gtmUaEventLabel': formatPrice(price),
    'gtmUaEventNonInteraction': 'False',
  });
};

export const sendOpenSchedule = () => {
  window.dataLayer.push({
    'event': 'gtmUaEvent',
    'gtmUaEventCategory': 'click',
    'gtmUaEventAction': 'delivery_schedule_info',
  });
};

export interface DeliveryContractSetData {
  event: 'delivery_contract_set' | 'delivery_contract_set_checkout',
  storeId: string,
  chain: string,
  deliveryType: string,
  scheduleDeliveryList: ScheduleDeliveryList,
}

export const sendDeliveryContractSet = (params: DeliveryContractSetData) => {
  const {
    event,
    storeId,
    chain,
    deliveryType,
    scheduleDeliveryList,
  } = params;

  const totalSlots = scheduleDeliveryList.reduce((sum, day) => sum + day.items.length, 0);
  const deliveryArea = scheduleDeliveryList[0]?.delivery_zone;
  const firstSlotDate = scheduleDeliveryList[0]?.date;
  const firstSlotTime = scheduleDeliveryList[0]?.items[0]?.time_range;

  window.dataLayer.push({
    'event': event,
    'store_id': storeId,
    'chain': chain,
    'delivery_type': deliveryType,
    'delivery_area': deliveryArea,
    'first_available_slot_date': firstSlotDate,
    'first_available_slot_time': firstSlotTime,
    'available_slot_number': 1,
    'total_slots': totalSlots,
  });
};

export const sendServiceFeeTooltipIsShown = (place: string) => {
  window.dataLayer.push({
    'event': 'tooltip.view',
    'place': place,
  });
};

export const sendAnotherDeliveryMethodClicked = (place: ChooseAnotherDeliveryMethodPlace) => {
  window.dataLayer.push({
    'event': 'about_another_method_delivery_click',
    'click_place': place,
  });
};

export const sendRepeatOrderClicked = (place: 'orders_list' | 'order_details' | 'cart') => {
  window.dataLayer.push({
    'event': 'repeat_order_button_click',
    'place': place,
  });
};

interface BaseEventItem {
  item_id: string;
  item_name: string;
  item_brand: string;
  item_category: string;
  item_variant: string;
  price: string;
  discount: string;
  item_is_in_stock: string;
}

interface EventItem extends BaseEventItem {
  quantity: number
}

const getBaseEventItemData = (product: Product) => {
  const { producer, unit, ean, category_id, title, discount, in_stock } = product;
  const brand = producer?.trademark || 'null';
  const price = getPriceStringByUnit(product.price, unit);

  let resultDiscount = '0';

  if (discount && discount.status) {
    resultDiscount = formatPrice(discount.old_price - product.price);
  }

  return {
    item_id: ean,
    item_name: title,
    item_brand: brand,
    item_category: category_id,
    item_is_in_stock: in_stock ? '1' : '0',
    item_variant: unit === weightMeasureEnum.kg ? BY_WEIGHT : BY_PIECE,
    price,
    discount: resultDiscount,
  };
};


const getProductIdsAndItemsForCart = (cartItems: CartItem[] = []) => {
  const items: EventItem[] = [];
  const product_ids: string[] = [];

  cartItems.forEach(p => {
    if (p.product) {
      const data = getBaseEventItemData(p.product);

      const item: EventItem = {
        ...data,
        quantity: p.amount,
      };

      items.push(item);
      product_ids.push(p.ean);
    }
  });

  return { items, product_ids };
};

export const sendCheckoutVisited = (params: SendCartParams) => {
  const { cartItems, itemsPrice, currency } = params;

  const { items, product_ids } = getProductIdsAndItemsForCart(cartItems);

  window.dataLayer.push({
    event: 'checkout_page_visited',
    order_value: formatPrice(itemsPrice),
    items,
    product_ids,
    currency_code: currency,
  });
};

interface SendCheckoutOrderParams {
  items: string[];
  itemsPrice: number;
  orderId: string;
  currency: Currency;
  cartItems: CartItem[];
}
export const sendCheckoutOrder = (params: SendCheckoutOrderParams) => {
  const { items, itemsPrice, currency, orderId, cartItems } = params;

  const GUID = window.localStorage?.getItem('GUID');
  const { itemsEsp } = getEspData(cartItems);

  window.dataLayer.push({
    'event': 'order_submitted',
    'order_id': orderId,
    'order_value': itemsPrice,
    'items': items,
    'currency_code': currency,
    'items_esp': itemsEsp,
    'GUID': GUID,
  });
};

export const sendChainsBurgerClick = () => {
  window.dataLayer.push({
    'event': 'chains_burger_menu_clicked',
  });
};

export const sendChainLogoClick = (chainId: string) => {
  window.dataLayer.push({
    'event': 'chain_button_clicked_from_burger_menu',
    'chain': chainId,
  });
};

export const sendZakazLogoClick = () => {
  window.dataLayer.push({
    'event': 'zakaz_mainsite_button_clicked',
  });
};

interface SendTimeWindowParams {
  deliveryType: DeliveryType;
  place: 'header' | 'checkout'
}

export const sendTimeWindow = (params: SendTimeWindowParams) => {
  const { deliveryType, place } = params;

  window.dataLayer.push({
    event: 'delivery_slot_selected',
    delivery_type: deliveryType,
    place,
  });
};

interface SendProductAddedParams {
  timer: string;
  price: number;
  ean: string;
  isPromotion: boolean;
}

export const sendProductAddedFromDetails = (params: SendProductAddedParams) => {
  const { ean, price, timer, isPromotion } = params;

  window.dataLayer.push({
    "event": "added_to_cart",
    "eventModel": {
      "items": [
        {
          "id": ean,
          "value": price,
          "promotion": isPromotion,
          "promotion_countdown": timer,
        },
      ],
    },
  });
};

export interface ItemEsp {
  productKey: string;
  price: string;
  quantity: string;
  currency: string;
}

export function getEspData(items: CartItem[]) {
  let cartSubtotal = 0;

  const itemsEsp = items.map((i) => {
    const itemPrice = i.price || 0;
    cartSubtotal += itemPrice;

    return {
      price: formatPrice(itemPrice),
      quantity: (i.unit === 'kg' ? gramsToKilos(i.amount) : i.amount) + '',
      currency: i.currency,
      productKey: i.ean,
    } as ItemEsp;
  });

  return { itemsEsp, cartSubtotal };
}

export const sendStatusCart = (items: CartItem[]) => {
  const { itemsEsp, cartSubtotal } = getEspData(items);

  const GUID = getGUID();
  window.localStorage?.setItem('GUID', GUID);

  window.dataLayer.push({ items_esp: null });
  window.dataLayer.push({
    event: 'status_cart',
    items_esp: itemsEsp,
    GUID,
    items_cart_amount: itemsEsp.length,
    value: cartSubtotal,
  });
};

export const sendAddToProductModalWasOpened = () => {
  window.dataLayer.push({
    'event': 'where_to_add_popup_viewed',
  });
};

export const sendProductAddedFromAddProductToModal = (addTo: 'cart' | 'active_order') => {
  window.dataLayer.push({
    'event': 'add_item_from_popup',
    'popup': 'where_to_add_a_product',
    'add_to': addTo,
  });
};

export const sendAddToModalWasClosed = () => {
  window.dataLayer.push({
    'event': 'where_to_add_popup_closed',
  });
};

export const sendClickHistoryItem = (item: string) => {
  window.dataLayer.push({
    event: "search_history_click",
    search_query: item,
  });
};

export const sendClickPopularItem = (item: string) => {
  window.dataLayer.push({
    event: "search_popular_tag",
    tag: item,
  });
};

const getDataFromViewProducts = (products: ProductWithAmount[] = []) => {
  const items: EventItem[] = [];
  const product_ids: string[] = [];

  products.forEach((p, i) => {
    if (p) {
      const data = getBaseEventItemData(p);

      const item = {
        ...data,
        quantity: p.amount || 1,
        index: i,
        google_business_vertical: "retail",
      };

      items.push(item);
      product_ids.push(p.ean);
    }
  });

  return { items, product_ids };
};

export const sendViewProductsListEvent = (
  listId: string,
  listName: string,
  products: ProductWithAmount[],
) => {
  const { items, product_ids } = getDataFromViewProducts(products);

  window.dataLayer.push({ ecommerce: null });
  window.dataLayer.push({
    event: 'view_item_list',
    ecommerce: {
      item_list_id: listId,
      item_list_name: listName,
      product_ids,
      items,
    },
  });
};

interface SendPurchaseEventParams extends Pick<CartContacts, 'email' | 'phone'> {
  cartItems: CartItem[];
  currency: string;
  orderId: string;
  chainId: string;
  coupon: string;
  itemsPrice: number;
  shipping: number;
}

export const sendPurchaseEvent = (params: SendPurchaseEventParams) => {
  const { cartItems, currency, orderId, chainId, itemsPrice, shipping, coupon, email, phone } = params;
  const { items, product_ids } = getProductIdsAndItemsForCart(cartItems);
  const { itemsEsp } = getEspData(cartItems);

  const itemsWithFullData = items.map((item, i) => ({
    ...item,
    index: i,
    google_business_vertical: "retail",
  }));

  const GUID = window.localStorage?.getItem('GUID');

  window.dataLayer.push({ ecommerce: null });
  window.dataLayer.push({
    event: "purchase",
    ecommerce: {
      transaction_id: orderId,
      currency,
      value: formatPrice(itemsPrice),
      shipping: formatPrice(shipping),
      coupon,
      affiliation: chainId,
      product_ids,
      items: itemsWithFullData,
      items_esp: itemsEsp,
      GUID,
      email,
      phone_number: phone,
    },
  });
};

const sendViewCartOrCheckout = (
  event: 'view_cart' | 'begin_checkout',
  params: SendCartParams,
) => {
  const { cartItems, currency, itemsPrice } = params;
  const { items, product_ids } = getProductIdsAndItemsForCart(cartItems);

  const itemsWithFullData = items.map((item, i) => ({
    ...item,
    index: i,
    google_business_vertical: "retail",
  }));

  window.dataLayer.push({ ecommerce: null });
  window.dataLayer.push({
    event,
    ecommerce: {
      currency: currency,
      value: formatPrice(itemsPrice),
      product_ids,
      items: itemsWithFullData,
    },
  });
};

export const sendViewCartEvent = (params: SendCartParams) => {
  sendViewCartOrCheckout('view_cart', params);
};

export const sendBeginCheckoutEvent = (params: SendCartParams) => {
  sendViewCartOrCheckout('begin_checkout', params);
};

const sendAddOrRemoveToCartEvent = (
  event: 'remove_from_cart' | 'add_to_cart',
  products: ProductWithAmount[],
  currency: string,
  place: string,
) => {
  const { items, product_ids } = getDataFromViewProducts(products);

  let value = 0;

  products.forEach(i => {
    const amount = i?.amount || 1;
    const price = getAmountedPrice(i.price, amount, i.unit);
    value += price;
  });

  window.dataLayer.push({ ecommerce: null });
  window.dataLayer.push({
    event,
    place,
    ecommerce: {
      currency,
      value: formatPrice(value),
      product_ids,
      items,
    },
  });
};

interface SendAddOrRemoveFromCartEventParams {
  products: ProductWithAmount[];
  currency: string;
  place: string;
}

export const sendRemoveFromCartEvent = (params: SendAddOrRemoveFromCartEventParams) => {
  const { products, currency, place } = params;

  sendAddOrRemoveToCartEvent('remove_from_cart', products, currency, place);
};

export const sendAddToCartEvent = (params: SendAddOrRemoveFromCartEventParams) => {
  const { products, currency, place } = params;

  sendAddOrRemoveToCartEvent('add_to_cart', products, currency, place);
};

const sendProductEvent = (
  event: 'add_to_wishlist' | 'view_item',
  product: ProductWithAmount,
) => {
  const { items, product_ids } = getDataFromViewProducts([product]);

  const amount = product?.amount || 1;
  const value = getAmountedPrice(product.price, amount, product.unit);

  window.dataLayer.push({ ecommerce: null });
  window.dataLayer.push({
    event,
    ecommerce: {
      currency: product.currency,
      value: formatPrice(value),
      product_ids,
      items,
    },
  });
};

export const sendAddProductToWishlistEvent = (product: ProductWithAmount) => {
  sendProductEvent('add_to_wishlist', product);
};

export const sendViewProductEvent = (product: ProductWithAmount) => {
  sendProductEvent('view_item', product);
};

export const sentNotificationFavoriteChangeClickEvent = (
  actionType: NotificationFavoriteData['actionType'],
) => {
  window.dataLayer.push({
    event: "change_product_lists_button_clicked",
    action: actionType === 'add' ? "add_to_list" : 'remove_from_list',
  });
};

export const sendOrderFeedbackSaved = (place: string) => {
  window.dataLayer.push({
    event: 'order_review_left',
    place,
  });
};

export const sendUpSaleProductsModal = (chain: string) => {
  window.dataLayer.push({
    'event': 'prekasa_items_shown',
    'chain': chain,
  });
};
