import { Dispatch } from 'redux';
import { impressionListEnum } from '../../utils/marketing/enhancedEcommerce';
import { Product, ProductUnit } from '../apiTypes';
import { resetImpressionListName, setImpressionListName } from '../catalogue/catalogueActions';
import { RootState } from '../reducers';
import { TrackProductDetailsEventAction } from './googleAnalyticsMiddleware';
import { TRACK_BACK_TO_SHOPPING, TRACK_ORDER_CLICK_EVENT, TRACK_PRODUCT_CLICK_EVENT, TRACK_PRODUCT_DETAILS_EVENT, TRACK_PRODUCT_IMPRESSION_EVENT, TRACK_REMARKETING_CATEGORY_PRODUCTS, TRACK_REMARKETING_ORDER_HISTORY_PRODUCTS, TRACK_REMARKETING_PRODUCTS, TRACK_SEARCH } from './googleAnalyticsTypes';

export const trackProductDetailsEvent = (
  currency: string,
  slug: string,
  ean: string,
  unit: ProductUnit,
  price: number,
  category_id: string,
  trademark: string | null,
) => (dispatch: Dispatch, getState: () => RootState) => {
  const { catalogue: { impressionListName } } = getState();

  const trackProductDetailsEventAction: TrackProductDetailsEventAction = {
    type: TRACK_PRODUCT_DETAILS_EVENT,
    payload: {
      data: {
        currency,
        slug,
        ean,
        unit,
        price,category_id,
        trademark,
      },
      impressionListName: impressionListName || impressionListEnum.DIRECT_LINK,
    },
  };

  dispatch(trackProductDetailsEventAction);
  dispatch(resetImpressionListName());
};

const trackProductClickEvent = (product, list, index) => dispatch => {
  return dispatch({
    type: TRACK_PRODUCT_CLICK_EVENT,
    payload: {
      product,
      list,
      index,
    },
  });
};

export const trackProductClick = (product, list, index) => dispatch => {
  dispatch(trackProductClickEvent(product, list, index));
  return dispatch(setImpressionListName(list));
};

export const trackProductImpressionEvent = (product, list, index) => dispatch => {
  return dispatch({
    type: TRACK_PRODUCT_IMPRESSION_EVENT,
    payload: {
      product,
      list,
      index,
    },
  });
};

export const trackOrderClickEvent = () => dispatch => {
  return dispatch({
    type: TRACK_ORDER_CLICK_EVENT,
  });
};

export const trackSearch = () => dispatch => {
  return dispatch({
    type: TRACK_SEARCH,
  });
};

export const trackBackToShopping = () => dispatch => {
  return dispatch({
    type: TRACK_BACK_TO_SHOPPING,
  });
};

export const trackRemarketingProducts = (products, page_type) => dispatch => {
  return dispatch({
    type: TRACK_REMARKETING_PRODUCTS,
    payload: {
      products,
      page_type,
    },
  });
};

export const trackRemarketingCategoryProducts = (
  products: Product[],
  page_type: string,
  category_id:string,
) => dispatch => {
  return dispatch({
    type: TRACK_REMARKETING_CATEGORY_PRODUCTS,
    payload: {
      products,
      page_type,
      category_id,
    },
  });
};

export const sendRemarketingOrderHistory = (products, totalPrice) => dispatch => {
  return dispatch({
    type: TRACK_REMARKETING_ORDER_HISTORY_PRODUCTS,
    payload: {
      products,
      totalPrice,
    },
  });
};
