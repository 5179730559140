import getStoresByCoords from '../../ajax/getStoresByCoords';
import getStoresByDeliveryType from '../../ajax/getStoresByDeliveryType';
import getStoresByDeliveryTypeAndCoords from '../../ajax/getStoresByDeliveryTypeAndCoords';
import { Address } from '../../redux/addressManagement/types';
import { FetchConfig, Store } from '../../redux/apiTypes';

/**
 *  Checks store in addressData property of passed address and returns new address with correct store.
 *  If passed address is the empty object, returns it.
 */
export async function getCheckedAddress(address: Address, config: FetchConfig): Promise<Address> {
  const userHasSelectedAddress = Object.values(address).length > 0;

  if (!userHasSelectedAddress) {
    return {};
  }

  // store is valid for pickup delivery type;
  let checkedStore: Store = address.addressData;
  const localDeliveryType = address.delivery;

  if (localDeliveryType === 'plan') {
    const [lat, lng] = address.coords.split(', ').map((c) => Number(c));
    const coords = { lat, lng };
    [checkedStore] = await getStoresByCoords(coords, config);
  } else if (localDeliveryType === 'nova_poshta') {
    [checkedStore] = await getStoresByDeliveryType('nova_poshta', config);
  } else if (localDeliveryType === 'nova_poshta_fresh') {
    const departmentCoords = address.department.coords;
    [checkedStore] = await getStoresByDeliveryTypeAndCoords(
      'nova_poshta_fresh',
      departmentCoords,
      config,
    );
  }

  return {
    ...address,
    addressData: checkedStore,
  };
}
