import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { ProductWithAmount } from '../modals/types';
import { ActiveNotificationState, NotificationFavoriteData, NotificationState } from './types';

const initialState: NotificationState = {
  type: null,
  messageContent: null,
  data: null,
};

const notificationSlice = createSlice({
  name: 'notification',
  initialState,
  reducers: {
    openNotification: (state: ActiveNotificationState, action: PayloadAction<ActiveNotificationState>) => {
      state.messageContent = action.payload.messageContent;
      state.type = action.payload.type;
      state.data = null;

      if(action.payload.data) {
        state.data = action.payload.data;
      }
    },
    closeNotification: (state) => {
      state.type = null;
      state.messageContent = null;
      state.data = null;
    },
  },
});

export const { openNotification, closeNotification } = notificationSlice.actions;

export const openErrorNotification = (messageKey: string) => {
  return openNotification({
    type: 'error',
    messageContent: messageKey,
    data: null,
  });
};

export const openTextNotification = (messageKey: string) => {
  return openNotification({
    type: 'message',
    messageContent: messageKey,
    data: null,
  });
};

export const openProductNotification = (product: ProductWithAmount) => {
  return openNotification({
    type: 'product',
    messageContent: null,
    data: product,
  });
};

export const openFavoriteNotification = (data: NotificationFavoriteData) => {
  return openNotification({
    type: 'favorite',
    messageContent: null,
    data,
  });
};

export default notificationSlice.reducer;
