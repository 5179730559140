import { RSAA } from 'redux-api-middleware';
import { selectAppConfig } from 'src/utils/appConfig/selectAppConfig';
import { CACHEABLE } from '../apiCacheMiddleware';
import { ERROR, REQUEST, SUCCESS } from '../constants';
import { RootState } from '../reducers';
import { GET_SERVICE_PAGES_LIST } from './servicePagesTypes';

export const getServicePages = () => (dispatch, getState: () => RootState) => {
  const state = getState();
  const { hostname: chainHost } = state.retailChain.data;
  const { fetchConfig, host: trueHost } = selectAppConfig(state);
  const { xChain, xVersion, apiRoot, language } = fetchConfig;

  return dispatch(
    {
      [CACHEABLE]:  {
        cacheKey: `${trueHost}-${xChain}-service_pages-${language}`,
        successActionType: GET_SERVICE_PAGES_LIST + SUCCESS,
      },
      [RSAA]: {
        method: 'GET',
        endpoint: `${apiRoot}/stores/default/service_pages/`,
        headers: {
          'Accept-Language': language,
          'x-chain': xChain,
          'x-version': xVersion,
        },
        types: [
          GET_SERVICE_PAGES_LIST + REQUEST,
          {
            type: GET_SERVICE_PAGES_LIST + SUCCESS,
            payload: async (action, state, res) => {
              const data = await res.json();

              return { data, trueHost, chainHost };
            },
          },
          GET_SERVICE_PAGES_LIST + ERROR,
        ],
      },
    },
  );
};
