import Head from 'next/head';
import { useRouter } from 'next/router';
import { FC } from 'react';
import { useAppConfig } from 'src/utils/appConfig/useAppConfig';
import { useAppSelector } from '../../../redux/hooks';
import { useDescription, useTitle } from '../../views/MainPageHead/MainPageHeadHooks';

/**
 * @tutorial
 * To avoid duplicate tags in head use the 'key' prop
 * with the same value. See Next.js docs:
 * https://nextjs.org/docs/api-reference/next/head
 *
 * <OpenGraphDefault /> must be placed before <Component... line
 */

const OpenGraphDefault: FC = () => {
  const { host, marketing: { FB_APP_ID } } = useAppConfig();

  const description = useDescription();
  const title = useTitle();
  const imgLink = useAppSelector(s => s.retailChain.data?.logo?.opengraph);
  const { asPath } = useRouter();

  return (
    <Head>
      <meta property='og:title' content={title} key='og:title' />
      <meta property='og:description' content={description} key='og:description' />
      <meta property='og:url' content={host + asPath} key='og:url' />
      <meta property='og:site_name' content={host} key='og:site_name' />
      {imgLink && <meta property='og:image' content={imgLink} key='og:image' />}
      <meta property='og:type' content='article' key='og:type' />
      <meta property='fb:app_id' content={FB_APP_ID} key='og:app_id' />
    </Head>
  );
};

export default OpenGraphDefault;
