import { createContext, ReactNode, useContext } from 'react';
import { useAppSelector } from 'src/redux/hooks';
import DeliveryPresets from './deliveryPresets/deliveryPresetsContext';
import useDeliveryPresetsService from './deliveryPresets/useDeliveryPresetsService';
import useFavoritesService, { FavoritesService } from './favorites/useFavoritesService';
import { LocalizeFunc, TranslationDictionary } from './localization/types';
import { useLocalizationService } from './localization/useLocalizationService';

export interface Services {
  favorites: FavoritesService;
  localization: LocalizeFunc;
}

interface ServicesProviderProps {
  children: ReactNode;
  translations: TranslationDictionary;
}

export const ServicesContext = createContext<Services>(null);

export const useServices = () => useContext(ServicesContext);

export const ServicesProvider = ({
  children,
  translations,
}: ServicesProviderProps) => {
  const isLoggedIn = useAppSelector((s) => s.user.isLoggedIn);
  const favorites = useFavoritesService(isLoggedIn);
  const localization = useLocalizationService(translations);
  const deliveryPresets = useDeliveryPresetsService(isLoggedIn);

  const contextValue = {
    favorites,
    localization,
  };

  return (
    <ServicesContext.Provider value={contextValue}>
      <DeliveryPresets.Provider value={deliveryPresets}>
        {children}
      </DeliveryPresets.Provider>
    </ServicesContext.Provider>
  );
};
