import { ActionReducerMapBuilder, createAction } from '@reduxjs/toolkit';
import { sendAddToCartEvent, sendAddingSingleProductToCart, sendStatusCart } from 'src/utils/marketing/enhancedEcommerce';
import { v4 as uuid } from 'uuid';
import { restrict } from '../../../../utils/cart/restrict';
import { saveCart } from '../../../../utils/cart/saveCart';
import { Product } from '../../../apiTypes';
import { openProductNotification } from '../../../notification';
import { RootState } from '../../../reducers';
import { CartItem, CartState } from '../../general/cartTypes';
import { setCartItems } from '../../general/operations/setCartItems';

interface PlaceProductActionPayload {
  ean: string;
  amount: number;
  product: Product;
}

const placeProductToClientCartAC = createAction<PlaceProductActionPayload>('clientCart/placeProductToClientCart');

export const placeProductToClientCart = (
  ean: string,
  amount: number,
  analyticsData: {
    place: string;
    product: Product;
  },
) => (dispatch, getState: () => RootState) => {
  const { product, place } = analyticsData;
  const state = getState();
  const currentItems = state.cart.cart;
  const currency = state.initialAppConfig.currency;
  const itemIndex = currentItems.findIndex((i) => i.ean === ean);
  const isProductInCart = itemIndex !== -1;
  const nextCartItems = [...currentItems];
  const { unit, quantity } = product;

  if (isProductInCart) {
    const curItem = nextCartItems[itemIndex];
    const limitedAmount = restrict(
      curItem.amount + amount,
      unit,
      quantity,
    );
    nextCartItems[itemIndex] = {
      ...curItem,
      amount: limitedAmount,
    };

    dispatch(setCartItems(nextCartItems));
  }

  if (!isProductInCart) {
    const limitedAmount = restrict(
      amount,
      unit,
      quantity,
    );
    dispatch(placeProductToClientCartAC({
      ean,
      amount: limitedAmount,
      product,
    }));
    dispatch(openProductNotification({ ...product, amount }));
  }

  const updatedCart = getState().cart.cart;

  saveCart(updatedCart);

  // ======== Analytics logic ========== start
  sendAddingSingleProductToCart(product, { amount, place, currency});
  sendAddToCartEvent({
    products: [{ ...product, amount }],
    currency,
    place,
  });
  sendStatusCart(updatedCart);
  // ======== Analytics logic ========== end
};

export const addPlaceProductToClientCartCases = (builder: ActionReducerMapBuilder<CartState>) => {
  builder
    .addCase(placeProductToClientCartAC, (state, { payload }) => {
      const { ean, amount, product } = payload;
      const { currency, price, unit } = product;
      const nextItem: CartItem = {
        ean,
        amount,
        currency,
        price,
        unit,
        product,
        isAvailable: true,
      };

      state.cart = [nextItem, ...state.cart];
      state.dataSignature = uuid();
    });
};
