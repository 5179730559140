import { ActionReducerMapBuilder, createAction } from '@reduxjs/toolkit';
import { Dispatch } from 'redux';
import { createAction as createRsaaAction } from 'redux-api-middleware';
import { selectAppConfig } from 'src/utils/appConfig/selectAppConfig';
import { ApiErrorShape, Product, ShoppingList } from '../apiTypes';
import { RootState } from '../reducers';
import { FavoritesInitialState } from './types';
import { selectCartItems } from '../cart/general/cartSelectors';
import { createListEanAmountMap } from './utils';
import { trackRemarketingProducts } from '../googleAnalytics/googleAnalyticsActions';
import { remarketingPageTypesEnum } from 'src/utils/marketing/enhancedEcommerce';

export const setEmptyProducts = createAction('favorites/setEmptyProducts');
export const getProductRequest = createAction('favorites/getProductRequest');
export const getProductSuccess = createAction('favorites/getProductSuccess');
export const getProductError = createAction<ApiErrorShape[]>('favorites/getProductError');

const getCurrentListProducts = (list: ShoppingList) => async (dispatch: Dispatch, getState: () => RootState) => {
  const state = getState();
  const storeId = state.storeManagement.store.id;
  const { fetchConfig } = selectAppConfig(state);
  const { xChain, xVersion, apiRoot, language } = fetchConfig;

  if (list.items.length) {
   const res = await dispatch(
      createRsaaAction<RootState, Product[], undefined>({
        method: 'POST',
        endpoint: `${apiRoot}/stores/${storeId}/products`,
        headers: {
          'Accept-Language': language,
          'Content-Type': 'application/json',
          'x-chain': xChain,
          'x-version': xVersion,
        },
        credentials: 'include',
        body: JSON.stringify({
          eans: list.items.map(item => item.ean),
        }),
        types: [
          getProductRequest.type,
          getProductSuccess.type,
          getProductError.type,
        ],
      }),
    );

    if (res.type === getProductSuccess.type) {
      const state = getState();
      const products = res.payload as Product[];

      const cartItems = selectCartItems(state);
      const eanAmountMap = createListEanAmountMap(cartItems);

      dispatch(trackRemarketingProducts(
        products.map(item => {
          const amount = eanAmountMap[item.ean]?.amount || 1;
          return ({
            ...item,
            total_price: item.price * amount,
          });
        }),
        remarketingPageTypesEnum.LISTS,
      ));
    }
  } else {
    dispatch(setEmptyProducts());
  }
};

export default getCurrentListProducts;

export function addGetCurrentListProducts(builder: ActionReducerMapBuilder<FavoritesInitialState>) {
  builder
    .addCase(getProductRequest, state => {
      state.isFetching = true;
      state.errors = null;
    })
    .addCase(getProductSuccess, (state, action) => {
      state.isFetching = false;

      state.products = action.payload;
    })
    .addCase(setEmptyProducts, state => {
      state.isFetching = false;
      state.products = [];
      state.errors = null;
    })
    .addCase(getProductError, (state, action) => {
      state.isFetching = false;
      state.errors = action.payload;
    });
}
