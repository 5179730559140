import { Dispatch, combineReducers } from 'redux';
import { RSAAResultAction } from 'redux-api-middleware';
import { InitialAppConfig } from 'src/utils/appConfig/getInitialAppConfig';
import addressManagement from '../redux/addressManagement/addressManagementReducers';
import analytics from './analytics/analyticsReducers';
import app from './app/appReducers';
import initialAppConfig from './appConfig/appConfigReducers';
import cart from './cart';
import serverCart from './cart/serverCart';
import catalogue from './catalogue/catalogueReducers';
import categories from './categories/categoriesReducers';
import favorites from './favorites';
import modals from './modals';
import notification from './notification';
import product from './product';
import referralProgram from './referralProgram';
import retailChain from './retailChain';
import search from './search';
import servicePages from './servicePages/servicePagesReducers';
import storeManagement from './storeManagement';
import toggles from './toggles/togglesReducers';
import translations from './translations/translationsReducers';
import user from './user/userReducer';
import userProfile from './userProfile/userProfileReducers';

const rootReducer = combineReducers({
  addressManagement,
  analytics,
  app,
  cart,
  catalogue,
  categories,
  favorites,
  initialAppConfig,
  modals,
  notification,
  product,
  referralProgram,
  retailChain,
  search,
  serverCart,
  servicePages,
  storeManagement,
  toggles,
  translations,
  user,
  userProfile,
});

export default rootReducer;

export type RootState = ReturnType<typeof rootReducer> & {
  /** @deprecated Use appConfigSelector */
  initialAppConfig: InitialAppConfig;
};

export type ReduxThunkAction<T = RSAAResultAction> = (
  dispatch: Dispatch, getState: () => RootState
) => Promise<T>;
