import { Dispatch } from 'redux';
import { RootState } from 'src/redux/reducers';
import { DeliveryPreset } from 'src/services/deliveryPresets/types';
import { getDeliveryPresetsFetcher } from 'src/services/deliveryPresets/useDeliveryPresetsRequests';
import { selectAppConfig } from 'src/utils/appConfig/selectAppConfig';
import { getAddressFromLocalStorage, setAddressInLocalStorage } from 'src/utils/delivery/addressLocalStorage';
import { Address } from '../../addressManagement/types';
import getCartDeliveryForNewAddress from '../../cart/serverCart/getCartDeliveryForNewAddress';
import requestGetCart from '../../cart/serverCart/requestGetCart';
import requestPostCartDelivery from '../../cart/serverCart/requestPostCartDelivery';
import { ServerCartDelivery } from '../../cart/serverCart/types';

export const prepareServerCartDataToInit = () => async (
  dispatch: Dispatch,
  getState: () => RootState,
) => {
  const localAddress: Address = getAddressFromLocalStorage();
  const userHasSelectedAddress = Object.values(localAddress).length > 0;

  if (userHasSelectedAddress) {
    let serverCartDelivery: ServerCartDelivery;

    if(!serverCartDelivery) {
      serverCartDelivery = getCartDeliveryForNewAddress(localAddress);
    }

    await dispatch(requestPostCartDelivery({ delivery: serverCartDelivery }));

    setAddressInLocalStorage();
  } else {
    await dispatch(requestGetCart());

    // ==================== Legacy e2e tests steps ====================
    /**
     * A lot of e2e tests perform the step “POST Delete all server data of the user”.
     * For the tests to work, we have to maintain legacy functionality:
     * - set last saved delivery to server cart.
     */
    const updatedState = getState();
    const serverCartData = updatedState.serverCart.data;
    const userHasServerDelivery = !!serverCartData.delivery;

    if (!userHasServerDelivery) {
      const { fetchConfig } = selectAppConfig(updatedState);

      const res = await getDeliveryPresetsFetcher(fetchConfig);

      if (res.ok) {
        const deliveryPresets = await res.json() as DeliveryPreset[];
        const lastSavedDelivery = deliveryPresets[0];

        await dispatch(
          requestPostCartDelivery({ delivery: lastSavedDelivery }),
        );
      }
    }
    // ==================== Legacy e2e tests steps ====================

  }
};
