import { ShoppingList, ShoppingListItem } from "../apiTypes";
import { EanFavoriteListMap } from "./types";

export const createEanListMap = (lists: ShoppingList[]): EanFavoriteListMap => {
  return lists.reduce((map, { id, items }) => {
    items.forEach(
      ({ ean }) => (map[ean] = map[ean] ? [...map[ean], id] : [id]),
    );
    return map;
  }, {});
};


export const createListEanAmountMap = <T extends ShoppingListItem>(items: T[]) => {
  return items.reduce((map, { ean, amount, unit }) => {
    map[ean] = { amount, unit };
    return map;
  }, {});
};
