import { FetchConfig, ShoppingList } from "src/redux/apiTypes";


const requestGetFavoritesLists = async (fetchConfig: FetchConfig): Promise<ShoppingList[]> => {
  const { xChain, xVersion, apiRoot, language } = fetchConfig;

  const response = await fetch(`${apiRoot}/user/lists`, {
    credentials: 'include',
    headers: {
      'Accept-Language': language,
      'Content-Type': 'application/json',
      'x-chain': xChain,
      'x-version': xVersion,
    },
  });

  if (!response.ok) {
    throw new Error('Fetching lists error');
  }

  return response.json();
};

export default requestGetFavoritesLists;
