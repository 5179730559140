import { FetchConfig, Offer } from '../../redux/apiTypes';

async function requestGetMainPromotion(
  fetchConfig: FetchConfig, deliveryService: string): Promise<null | Offer> {
  const { xChain, apiRoot, xVersion, language } = fetchConfig;

  try {
    const response = await fetch(
      `${ apiRoot }/promotions/main/?delivery_service=${ deliveryService }`,
      {
        headers: {
          'Accept-Language': language,
          'x-chain': xChain,
          'x-version': xVersion,
        },
      },
    );

    if (!response.ok) {
      return null;
    }

    return response.json();
  } catch {
    return null;
  }
}

export default requestGetMainPromotion;
