import { createSelector, Selector } from '@reduxjs/toolkit';
import { RootState } from '../../reducers';
import { CartItem } from '../general/cartTypes';


export const selectClientCartItems: Selector<RootState, CartItem[]> = (
  state,
) => state.cart.cart;

export const selectClientCartOverweight: Selector<RootState, null | number> = createSelector(
  [
    (state: RootState) => state.addressManagement.address,
    (state: RootState) => state.cart.cartPrice['total_weight'],
  ],
  (address, totalWeight = 0) => {

    if(
      !address.department ||
      (
        address.delivery !== 'nova_poshta' &&
        address.delivery !== 'nova_poshta_fresh'
      )
    ) {
      return null;
    }

    const maxWeightOfDepartment = address.department['max_weight_allowed'];
    const overweight = totalWeight - maxWeightOfDepartment;

    return overweight > 0 ? overweight : null;
  },
);
