import { Dispatch } from 'redux';
import { setAddressInLocalStorage } from 'src/utils/delivery/addressLocalStorage';
import setDeliveryTypeCookie from '../../utils/delivery/setDeliveryTypeCookie';
import { setAddress } from './addressManagementActions';
import { Address } from './types';

const setSelectedAddress = (
  address: Address,
) => (dispatch: Dispatch) => {
  setDeliveryTypeCookie(address.delivery);
  setAddressInLocalStorage(address);
  dispatch(setAddress(address));
};

export default setSelectedAddress;
