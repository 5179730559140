import { useAppSelector } from 'src/redux/hooks';
import { selectRetailChainName } from 'src/redux/retailChain/selectors';
import useLocalization from 'src/services/localization/useLocalization';
import { useAppConfig } from 'src/utils/appConfig/useAppConfig';

export const useTitle = (): string => {
  const { chain } = useAppConfig();
  const chainName = useAppSelector(selectRetailChainName);

  const localize = useLocalization();
  const chainTitleGettersMap = {
    'default': () => `${chainName} ${localize(`MAIN_PAGE.title`)} ${localize(`meta.${chain}.in_cities`)} ${localize('meta.general.to_home')}`,
  };

  return chainTitleGettersMap[chain] ? chainTitleGettersMap[chain]() : chainTitleGettersMap.default();

};

export const useDescription = (): string => {
  const chainName = useAppSelector(selectRetailChainName);
  const localize = useLocalization();
  const mainPageDescription = `${chainName} - ${localize('meta.description.main_page')}`;

  return mainPageDescription;
};
