import { useDispatch } from 'react-redux';
import setNewDelivery from 'src/components/containers/AddressManagementModal/setNewDelivery';
import selectAddress from 'src/redux/cart/general/selectAddress';
import { useAppSelector } from 'src/redux/hooks';
import { DeliveryPreset } from './types';
import useDeliveryPresetsRequests from './useDeliveryPresetsRequests';

export default function useDeliveryPresetsService(isLoggedIn: boolean) {
  const {
    requestGetDeliveryPresets,
    requestPostDeliveryPresets,
    requestDeleteDeliveryPresets,
    ...rest
  } = useDeliveryPresetsRequests(isLoggedIn);

  const dispatch = useDispatch();
  const selectedAddress = useAppSelector(selectAddress);
  const currentStore = useAppSelector((s) => s.storeManagement.store);

  const editDeliveryPreset = async (preset: DeliveryPreset) => {
    const currentPresetId = preset.hash;

    const newPresetId = await requestPostDeliveryPresets(preset);

    if (newPresetId !== currentPresetId) {
      await requestDeleteDeliveryPresets(currentPresetId);
    }

    await requestGetDeliveryPresets();

    const presetIsUserDelivery = selectedAddress && selectedAddress.id === preset.hash;

    if (presetIsUserDelivery) {
      await dispatch(setNewDelivery(preset, currentStore));
    }

    return newPresetId;
  };

  const deleteDeliveryPreset = async (id: string) => {
    await requestDeleteDeliveryPresets(id);
    await requestGetDeliveryPresets();
  };

  return {
    updateDeliveryPresets: requestGetDeliveryPresets,
    editDeliveryPreset,
    deleteDeliveryPreset,
    ...rest,
  };
}
