import { ApiErrorResponse, ServicePage } from "../apiTypes";
import { ERROR, REQUEST, SERVICE_PAGE_PREFIX, SUCCESS } from "../constants";
import createReducer from "../createReducer";
import { GET_SERVICE_PAGES_LIST } from "./servicePagesTypes";

interface ServicePagesState {
  isFetching: boolean;
  data: ServicePage[];
  error: ApiErrorResponse | null;
}

const initialServicePagesState: ServicePagesState = {
  isFetching: false,
  data: [],
  error: null,
};

const reducer = createReducer<ServicePagesState>(
  initialServicePagesState,
  {
    [GET_SERVICE_PAGES_LIST + REQUEST]: state => (
      {
        ...state,
        isFetching: true,
        error: null,
      }
    ),
    [GET_SERVICE_PAGES_LIST + SUCCESS]: (state, action) => {
      const { data: payloadData, trueHost, chainHost } = action.payload;

      const data = payloadData
        .filter(page =>
          page.id.includes(SERVICE_PAGE_PREFIX) &&
          page.is_public,
        ).map(page => ({
          ...page,
          id: page.id.replace(SERVICE_PAGE_PREFIX, ''),
          web_url: page.web_url.replace(chainHost, trueHost),
        }));

      return {
        ...state,
        isFetching: false,
        data,
      };
    },
    [GET_SERVICE_PAGES_LIST + ERROR]: (state, action) => (
      {
        ...state,
        isFetching: false,
        error: action.payload,
      }
    ),
  },
);

export default reducer;
