import { createReducer } from '@reduxjs/toolkit';
import { addGetCurrentListProducts } from './getCurrentListProducts';
import { FavoritesInitialState } from './types';

export const favoritesInitialState: FavoritesInitialState = {
  isFetching: false,
  products: [],
  errors: null,
};

const reducer = createReducer(favoritesInitialState, builder => {
  addGetCurrentListProducts(builder);
});

export default reducer;
