import { createSelector, Selector } from '@reduxjs/toolkit';
import { Address } from '../../addressManagement/types';
import { RootState } from '../../reducers';
import convertToAddress from '../serverCart/convertToAddress';
import validateWarehouse from './helpers/validateWarehouse';

const selectAddress: Selector<
  RootState, Address
> = createSelector(
  [
    state => state.app.cartType,
    state => state.addressManagement.address,
    state => state.serverCart.data.delivery,
    state => state.storeManagement.store,
  ],
  (
    cartType,
    address,
    delivery,
    store,
  ) => {
    // When the cart type is null, it should return the result
    // as with the cart type "client". This is required for SSR.
    if (cartType === 'client' || !cartType) {
      return address;
    }

    /** Return empty object like in getAddressFromLocalStorage */
    if (!delivery || !validateWarehouse(delivery)) {
      return {};
    }

    return convertToAddress(delivery, [], store);
  },
);

export default selectAddress;
