import { createReducer } from '@reduxjs/toolkit';
import { addGetRetailChain } from './requestGetRetailChain';
import { RetailChainState } from './types';

const initialState: RetailChainState = {
  data: null,
  status: 'idle',
  error: null,
};

const reducer = createReducer(initialState, builder => {
  addGetRetailChain(builder);
});

export default reducer;
