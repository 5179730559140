import { ShoppingList } from "src/redux/apiTypes";
import { EanFavoriteListMap } from "src/redux/favorites/types";
import getFavoriteListIdsFromSessionStorage from "./getFavoriteListIdsFromSessionStorage";

interface GetAddingRemovingListsParams {
  ean: string;
  eanListsMap: EanFavoriteListMap;
  lists: ShoppingList[];
  currentListId: null | string;
}

const getAddingRemovingLists = (params: GetAddingRemovingListsParams) => {
  const { eanListsMap, lists, ean, currentListId  } = params;

  let addingLists: string[] = [];
  let removingLists: string[] = [];

  if (currentListId !== null) { // for only favorite page
    if (eanListsMap[ean]?.includes(currentListId)) {
      removingLists = [currentListId];
    } else {
      addingLists = [currentListId];
    }
  } else { // for all pages
    if (eanListsMap[ean]) {
      removingLists = eanListsMap[ean];
    } else {
      addingLists = getFavoriteListIdsFromSessionStorage(lists);
    }
  }

  return {
    addingLists,
    removingLists,
  };
};

export default getAddingRemovingLists;
