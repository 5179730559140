import { AnyAction } from 'redux';
import { makeProductImpression, ProductDetails, sendBackToShopping, sendImpression, sendOrderClickGoal, sendProductClick, sendProductDetails, sendRemarketingCategoryProducts, sendRemarketingOrderHistory, sendRemarketingProducts, sendSearch } from '../../utils/marketing/enhancedEcommerce';
import { RootState } from '../reducers';
import { TRACK_BACK_TO_SHOPPING, TRACK_ORDER_CLICK_EVENT, TRACK_PRODUCT_CLICK_EVENT, TRACK_PRODUCT_DETAILS_EVENT, TRACK_PRODUCT_IMPRESSION_EVENT, TRACK_REMARKETING_CATEGORY_PRODUCTS, TRACK_REMARKETING_ORDER_HISTORY_PRODUCTS, TRACK_REMARKETING_PRODUCTS, TRACK_SEARCH } from './googleAnalyticsTypes';

export interface TrackProductDetailsEventAction extends AnyAction {
  type: 'TRACK_PRODUCT_DETAILS_EVENT'
  payload: {
    data: ProductDetails
    impressionListName: string
  }
}

const actionHandlers = {
  [TRACK_PRODUCT_DETAILS_EVENT]: (
    _: RootState, action: TrackProductDetailsEventAction,
  ) => {
    const {data, impressionListName} = action.payload;
    sendProductDetails(data, impressionListName);
  },
  [TRACK_PRODUCT_CLICK_EVENT]: (_, action) => {
    const { product, list, index } = action.payload;
    sendProductClick(product, list, index + 1);
  },
  [TRACK_PRODUCT_IMPRESSION_EVENT]: (_, action) => {
    const { product, list, index } = action.payload;
    const impression = makeProductImpression(product, list, index + 1);
    sendImpression(impression);
  },
  [TRACK_ORDER_CLICK_EVENT]: () => {
    sendOrderClickGoal();
  },
  [TRACK_SEARCH]: () => {
    sendSearch();
  },
  [TRACK_BACK_TO_SHOPPING]: () => {
    sendBackToShopping();
  },
  [TRACK_REMARKETING_PRODUCTS]: (_, action) => {
    const {
      payload: {
        products,
        page_type,
      },
    } = action;
    sendRemarketingProducts(products, page_type);
  },
  [TRACK_REMARKETING_CATEGORY_PRODUCTS]: (_, action) => {
    const {
      payload: {
        products,
        page_type,
        category_id,
      },
    } = action;
    sendRemarketingCategoryProducts(products, page_type, category_id);
  },
  [TRACK_REMARKETING_ORDER_HISTORY_PRODUCTS]: (_, action) => {
    sendRemarketingOrderHistory(action.payload.products, action.payload.totalPrice);
  },
};

const googleAnalyticsMiddleware = store => next => action => {
  const handler = actionHandlers[action.type];

  if (handler) {
    handler(store, action);
  }

  return next(action);
};

export default googleAnalyticsMiddleware;
