import { NpStreet } from 'src/modules/AddressManagement/AddressManagement.types';
import { DEFAULT_PLAN_TYPE } from 'src/redux/constants';
import { Address } from '../../addressManagement/types';
import { Coords } from '../../apiTypes';
import { ServerCartDelivery, ServerCartDeliveryNovaPoshta, ServerCartDeliveryNovaPoshtaAddress, ServerCartDeliveryPickup, ServerCartDeliveryPlan } from './types';

function getCartDeliveryForNewAddress(
  address: Address,
): ServerCartDelivery {
  let plan: ServerCartDeliveryPlan;
  let pickup: ServerCartDeliveryPickup;
  let nova_poshta: ServerCartDeliveryNovaPoshta;
  let nova_poshta_fresh: ServerCartDeliveryNovaPoshta;
  let nova_poshta_address: ServerCartDeliveryNovaPoshtaAddress;

  const { delivery: deliveryType } = address;

  if (deliveryType === 'plan') {
    const {
      city,
      street,
      building,
      coords: coordsString,
    } = address;

    let coords: Coords;

    if (coordsString) {
      const [lat, lng] = coordsString.split(', ').map(v => Number(v));
      coords = { lat, lng };
    }

    plan = {
      city,
      street: street as string,
      building,
      coords,
      type: DEFAULT_PLAN_TYPE,
    };
  }

  if (deliveryType === 'pickup') {
    const { addressData, pickupZone } = address;
    const { id } = addressData;

    pickup = {
      store_id: id,
      pickup_zone: pickupZone,
      store: addressData,
    };
  }

  if (deliveryType === 'nova_poshta' || deliveryType === 'nova_poshta_fresh') {
    const { locality, department } = address;

    const npData: ServerCartDeliveryNovaPoshta = {
      settlement: {
        id: locality?.value,
        name: locality?.label,
        warehouse: locality?.warehouse,
        address_delivery_not_allowed: locality?.address_delivery_not_allowed,
      },
      warehouse: {
        id: department?.value,
        settlement_id: locality?.value,
        name: department?.label,
        max_weight_allowed: department?.max_weight_allowed,
        number: department?.number,
        coords: department?.coords,
        type: department?.type,
      },
    };

    if (deliveryType === 'nova_poshta') {
      nova_poshta = npData;
    }

    if (deliveryType === 'nova_poshta_fresh') {
      nova_poshta_fresh = npData;
    }
  }

  if (deliveryType === 'nova_poshta_address') {
    const { locality, street, building } = address;

    nova_poshta_address = {
      address: {
        settlement: {
          id: locality?.value,
          name: locality?.label,
          warehouse: locality?.warehouse,
          address_delivery_not_allowed: locality?.address_delivery_not_allowed,
        },
        street: street as NpStreet,
        building,
      },
    };
  }

  const delivery: ServerCartDelivery = {
    type: deliveryType,
    address: {
      plan,
      pickup,
      nova_poshta,
      nova_poshta_fresh,
      nova_poshta_address,
    },
    hash: address.id,
  };

  return delivery;
}

export default getCartDeliveryForNewAddress;
