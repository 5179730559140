import { Language } from 'src/utils/appConfig/getInitialAppConfig';
import { AppConfig } from 'src/utils/appConfig/selectAppConfig';
import { Toggles } from 'src/utils/toggles/types';
import { NOT_FOUND } from '../../../redux/constants';
import handleApi from './redirectHandlers/handleApi';
import handleB2BLink from './redirectHandlers/handleB2BLink';
import handleCase from './redirectHandlers/handleCase';
import handleCategory from './redirectHandlers/handleCategory';
import handleCustomCategory from './redirectHandlers/handleCustomCategory';
import handleDoubleSlash from './redirectHandlers/handleDoubleSlash';
import handleEAN from './redirectHandlers/handleEAN';
import handleFirstFilterParamOfCategory from './redirectHandlers/handleFirstFilterParamOfCategory';
import handleHoreca from './redirectHandlers/handleHoreca';
import handleNoLanguage from './redirectHandlers/handleNoLanguage';
import handlePreferredLanguage from './redirectHandlers/handlePreferredLanguage';
import handleProfile from './redirectHandlers/handleProfile';
import handlePromotions from './redirectHandlers/handlePromotions';
import handleRecipes from './redirectHandlers/handleRecipes';
import handleStore from './redirectHandlers/handleStore';
import handleUnderscore from './redirectHandlers/handleUnderscore';

export interface HandlerOptions {
  path: string;
  clientType?: string;
  storeId?: string;
  preferredLanguage?: Language;
  toggles: Toggles;
  appConfig: AppConfig;
}

export interface ModifiedPath {
  modifiedPath: string;
  isHandled?: boolean;
  redirectType?: number;
}

type HandlerResult = string | ModifiedPath;

export interface HandlerFunc {
  (options: HandlerOptions): Promise<HandlerResult> | HandlerResult;
}

export interface RedirectPathContext {
  path: string;
  preferredLanguage?: Language;
  clientType?: string;
  storeId?: string;
}

export interface GetRedirectPathFunc {
  (
    context: RedirectPathContext,
    toggles: Toggles,
    appConfig: AppConfig,
  ): Promise<{
    newPath: string;
    redirectType: number;
    isNoLangRote?: boolean;
  }>;
}

export const noLangRoutes = [
  '/robots.txt/',
  '/manifest.json/',
  '/sw.js/',
  '/apple-app-site-association/',
  '/.well-known/apple-developer-merchantid-domain-association/',
];

const handlers: HandlerFunc[] = [
  handleApi, // technical handler for e2e tests
  handleCase,
  handleNoLanguage,
  handlePreferredLanguage,
  handleDoubleSlash,
  handleB2BLink, // should be before handleClientType
 // handleClientType,
  handleStore,
  handleProfile,
  handlePromotions,
  handleRecipes,
  handleEAN,
  handleCustomCategory, // should be before handleCategory
  handleCategory,
  handleFirstFilterParamOfCategory,
  handleUnderscore, // should be after handleFirstFilterParamOfCategory
  handleHoreca,
];

const getRedirectPath: GetRedirectPathFunc = async (context, toggles, appConfig) => {
  const { path } = context;
  let newPath = path;
  let redirectType = 301;

  if (noLangRoutes.includes(path)) {
    return { newPath, redirectType, isNoLangRote: true };
  }

  // handleCategory(modifiedPath)
  for (const handler of handlers) {
    const handledPath = await handler({
      ...context,
      path: newPath,
      toggles,
      appConfig,
    });

    if (typeof handledPath === 'object') {
      redirectType = handledPath.redirectType || 301;
      newPath = handledPath.modifiedPath;

      if (handledPath.isHandled) {
        return { newPath, redirectType };
      }
    } else {
      newPath = handledPath;
    }

    // If NOT_FOUND brake the loop
    if (newPath === NOT_FOUND) {
      return { newPath, redirectType };
    }
  }

  return { newPath, redirectType };
};

export default getRedirectPath;
