import { Toggles } from 'src/utils/toggles/types';
import createReducer from '../createReducer';

export const setToggles = (toggles: Toggles) => ({
  type: 'SET_TOGGLES',
  payload: toggles,
});

export const initialState = {} as Toggles;

const reducer = createReducer(initialState, {
  SET_TOGGLES: (_state, action) => {
    return {
      ...action.payload,
    };
  },
});

export default reducer;
