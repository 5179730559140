import { createSelector, Selector } from '@reduxjs/toolkit';
import { RootState } from "../reducers";

export const selectRetailChainName: Selector<RootState, string> = createSelector(
  [
    state => state.retailChain.data,
    state => state.initialAppConfig.chain,
  ],
  (data, chain) => data?.name || chain,
);
